import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	Col,
	FormGroup,
	Input,
	Row,
	Table,
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AgendaAnoLetivo from 'views/components/AgendaAnoLetivo';
import ArquivosAnoLetivo from 'views/components/ArquivosAnoLetivo';
import CalendarModal from 'views/components/CalendarModal';
import CargaHorariaAnoLetivo from 'views/components/CargaHorariaAnoLetivo';
import ConfirmModal from 'views/components/ConfirmModal';
import EscolasAnoLetivo from 'views/components/EscolasAnoLetivo';
import LoaderModal from 'views/components/LoaderModal';
import TransportesAnoLetivo from 'views/components/TransportesAnoLetivo';

import AnosLetivosService from 'services/AnosLetivosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import EntesService from 'services/EntesService';
import FeriadosService from 'services/FeriadosService';
import LoginService from 'services/LoginService';

import Papel from 'assets/csv/papeis.json';
import SituacaoAnoLetivo from 'assets/csv/situacoes-ano-letivo.json';

class AnoLetivo extends React.Component {
	constructor(props) {
		super(props);

		this.situacoesAnosLetivos = [
			SituacaoAnoLetivo.ABERTO,
			SituacaoAnoLetivo.CONSOLIDADO,
		];

		this.state = {
			showAlert: false,
			showCalendarModal: false,
			showLoaderModal: false,
			showConfirmModal: false,

			erro: null,
		};

		this.entesService = new EntesService();
		this.loginService = new LoginService();
		this.anosLetivosService = new AnosLetivosService();
		this.feriadosService = new FeriadosService();
		this.diasCanceladosService = new DiasCanceladosService();
		this.diasExtrasService = new DiasExtrasService();

		this.consolidarAnoLetivo = this.consolidarAnoLetivo.bind(this);
		this.confirmarConsolidacaoAnoLetivo = this.confirmarConsolidacaoAnoLetivo.bind(this);

		this.reabrirAnoLetivo = this.reabrirAnoLetivo.bind(this);
		this.confirmarReaberturaAnoLetivo = this.confirmarReaberturaAnoLetivo.bind(this);
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {
			const dadosEnte = await this.entesService.carregarDadosEnte();

			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const feriados = await this.feriadosService
				.carregarFeriadosPorAnoLetivo(anoLetivo.id);

			const diasCancelados = await this.diasCanceladosService
				.carregarDiasCanceladosPorAnoLetivo(anoLetivo.id);

			const diasExtras = await this.diasExtrasService
				.carregarDiasExtrasPorAnoLetivo(anoLetivo.id);

			Object.assign(anoLetivo, { feriados, diasCancelados, diasExtras });

			this.setState({
				dadosEnte,
				anoLetivo,
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}


	confirmarConsolidacaoAnoLetivo() {
		this.setState({
			showConfirmModal: true,
			confirmModalText: `Confirme a consolidação do ano letivo ${this.state.anoLetivo.ano}`,
			confirmModalButtonText: 'Consolidar',
			confirmModalAction: this.consolidarAnoLetivo,
		});
	}

	async consolidarAnoLetivo() {
		this.setState({ showAlert: false, showLoaderModal: true });
		try {
			await this.anosLetivosService.consolidarAnoLetivo(this.state.anoLetivo.id);

			this.setState({
				anoLetivo: { ...this.state.anoLetivo, situacao: SituacaoAnoLetivo.CONSOLIDADO.value },
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Ano letivo consolidado com sucesso',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao consolidar ano letivo',
			});
		}
	}

	confirmarReaberturaAnoLetivo() {
		this.setState({
			showConfirmModal: true,
			confirmModalText: `Confirme a reabertura do ano letivo ${this.state.anoLetivo.ano}`,
			confirmModalButtonText: 'Reabrir',
			confirmModalAction: this.reabrirAnoLetivo,
		});
	}

	async reabrirAnoLetivo() {
		this.setState({ showAlert: false, showLoaderModal: true });
		try {
			await this.anosLetivosService.reabrirAnoLetivo(this.state.anoLetivo.id);

			this.setState({
				anoLetivo: { ...this.state.anoLetivo, situacao: SituacaoAnoLetivo.ABERTO.value },
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Ano letivo reaberto com sucesso',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao reabrir ano letivo',
			});
		}
	}

	conteudoPagina() {
		return this.state.anoLetivo ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem active>Ano letivo {this.state.anoLetivo.ano}</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<Tabs>
								<TabList style={{ marginBottom: 50 }}>
									<Tab>Ano letivo {this.state.anoLetivo.ano}</Tab>
									<Tab>Escolas</Tab>
									{
										this.props.role === Papel.GESTOR.value && this.state.dadosEnte.transportes && <Tab>
											Rotas Transportes
										</Tab>
									}
									<Tab>Agenda da SME</Tab>
									<Tab>Arquivos</Tab>
									{
										false && this.props.role === Papel.GESTOR.value && <Tab>Carga Horária</Tab>
									}
								</TabList>
								<TabPanel>
									<Row className="align-items-end">
										<Col md="2">
											<FormGroup className="has-label">
												<label>Situação</label>
												<Input
													disabled
													type="text"
													value={this.situacoesAnosLetivos[this.state.anoLetivo.situacao].label}
												/>
											</FormGroup>
										</Col>
										{this.props.role === Papel.GESTOR.value
											&& this.state.anoLetivo.situacao === SituacaoAnoLetivo.ABERTO.value
											&& <Col>
												<Button color="primary" onClick={() => this.confirmarConsolidacaoAnoLetivo()}>
													Consolidar ano letivo
												</Button>
											</Col>
										}
										{this.props.role === Papel.GESTOR.value
											&& this.state.anoLetivo.situacao === SituacaoAnoLetivo.CONSOLIDADO.value
											&& <Col>
												<Button color="primary" onClick={() => this.confirmarReaberturaAnoLetivo()}>
													Reabrir ano letivo
												</Button>
											</Col>
										}
										<Col className="text-right">
											<Button color="primary" onClick={() => { this.setState({ showCalendarModal: true }); }}>
												<span className="btn-label">
													<i className="fa fa-calendar clickable-icon" />
												</span>
												{' '}Calendário
											</Button>
										</Col>
									</Row>
									<br />
									<Row>
										<Col md="12">
											<Table className="table-head-bordered">
												<thead>
													<tr>
														<th colSpan="2" className="text-center">1º bimestre</th>
														<th colSpan="2" className="text-center">2º bimestre</th>
														<th colSpan="2" className="text-center">3º bimestre</th>
														<th colSpan="2" className="text-center">4º bimestre</th>
														<th colSpan="2" className="text-center">Recesso Junino</th>
														<th style={{ width: '15%' }} rowSpan="2" className="text-center">Data limite para consilidação do ano letivo</th>
													</tr>
													<tr>
														<th className="text-center">Início</th>
														<th className="text-center">Fim</th>
														<th className="text-center">Início</th>
														<th className="text-center">Fim</th>
														<th className="text-center">Início</th>
														<th className="text-center">Fim</th>
														<th className="text-center">Início</th>
														<th className="text-center">Fim</th>
														<th className="text-center">Início</th>
														<th className="text-center">Fim</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="text-center">{this.state.anoLetivo.dataInicioBimestre1}</td>
														<td className="text-center">{this.state.anoLetivo.dataFimBimestre1}</td>
														<td className="text-center">{this.state.anoLetivo.dataInicioBimestre2}</td>
														<td className="text-center">{this.state.anoLetivo.dataFimBimestre2}</td>
														<td className="text-center">{this.state.anoLetivo.dataInicioBimestre3}</td>
														<td className="text-center">{this.state.anoLetivo.dataFimBimestre3}</td>
														<td className="text-center">{this.state.anoLetivo.dataInicioBimestre4}</td>
														<td className="text-center">{this.state.anoLetivo.dataFimBimestre4}</td>
														<td className="text-center">{this.state.anoLetivo.dataInicioRecesso}</td>
														<td className="text-center">{this.state.anoLetivo.dataFimRecesso}</td>
														<td className="text-center">{this.state.anoLetivo.dataLimite}</td>
													</tr>
												</tbody>
											</Table>
										</Col>
									</Row>
									{
										this.state.dadosEnte.limitesBimestrais && <Row>
											<Col md="6">
												<Table className="table-head-bordered">
													<thead>
														<tr>
															<th colSpan="4" className="text-center">Datas limite para lançamento das informações do bimestre nos diários</th>
														</tr>
														<tr>
															<th className="text-center">1º bimestre</th>
															<th className="text-center">2º bimestre</th>
															<th className="text-center">3º bimestre</th>
															<th className="text-center">4º bimestre</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="text-center">{this.state.anoLetivo.dataLimiteBimestre1}</td>
															<td className="text-center">{this.state.anoLetivo.dataLimiteBimestre2}</td>
															<td className="text-center">{this.state.anoLetivo.dataLimiteBimestre3}</td>
															<td className="text-center">{this.state.anoLetivo.dataLimiteBimestre4}</td>
														</tr>
													</tbody>
												</Table>
											</Col>
										</Row>
									}
									<Row>
										<Col>
											<Button
												color="primary" disabled={this.state.buttonDisabled}
												onClick={() => this.props.history.push(`${this.props.layout}/anosletivos`)} >
												Voltar
											</Button>
										</Col>
									</Row>
								</TabPanel>
								<TabPanel>
									<EscolasAnoLetivo
										{...this.props}
										idAnoLetivo={this.props.match.params.idAnoLetivo}
									/>
								</TabPanel>
								{
									this.props.role === Papel.GESTOR.value && this.state.dadosEnte.transportes && <TabPanel>
										<TransportesAnoLetivo
											{...this.props}
											idAnoLetivo={this.props.match.params.idAnoLetivo}
										/>
									</TabPanel>
								}
								<TabPanel>
									<AgendaAnoLetivo
										{...this.props}
										callbackMessage={(message) => this.setState(message)}
										idAnoLetivo={this.props.match.params.idAnoLetivo}
									/>
								</TabPanel>
								<TabPanel>
									<ArquivosAnoLetivo
										{...this.props}
										callbackMessage={(message) => this.setState(message)}
										idAnoLetivo={this.props.match.params.idAnoLetivo}
									/>
								</TabPanel>
								{
									false && this.props.role === Papel.GESTOR.value && <TabPanel>
										<CargaHorariaAnoLetivo
											{...this.props}
											callbackMessage={(message) => this.setState(message)}
											idAnoLetivo={this.props.match.params.idAnoLetivo}
										/>
									</TabPanel>
								}
							</Tabs>
						</CardBody>
					</Card>
				</Col >
			</Row >
			<CalendarModal
				anoLetivo={this.state.anoLetivo}
				isOpen={this.state.showCalendarModal}
				toggle={() => this.setState({ showCalendarModal: !this.state.showCalendarModal })} />
			<ConfirmModal
				isOpen={this.state.showConfirmModal}
				callback={(confirm) => {
					this.setState({ showConfirmModal: false });
					if (confirm) {
						this.state.confirmModalAction();
					}
				}}
				confirmText={this.state.confirmModalButtonText}
				text={this.state.confirmModalText} />
			<LoaderModal
				isOpen={this.state.showLoaderModal}
				text='Consolidando ano letivo...' />
		</>
			: <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#34B5B8"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações do ano letivo
						</div>
					</Card>
				}
			</div>
		);
	}
}

export default withRouter(AnoLetivo);
