import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import Aulas from 'views/components/Aulas';
import Tarefas from 'views/components/Tarefas';
import Forum from 'views/components/Forum';

import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';

import Turno from 'assets/csv/turnos.json';
import ArquivosTurmaVirtual from 'views/components/ArquivosTurmaVirtual';

class TurmaVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();

    this.state = {
      showAlert: false,

      erro: false,

      dadosTabela: {
        header: [['Aulas', '60%'], ['Data', '20%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'data'],
        rows: [],
        total: undefined,
      },
    };
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const matricula = await this.turmasService
        .carregarMatriculaPorId(this.props.match.params.idMatricula);

      const turma = await this.turmasService
        .carregarTurma(matricula.idTurma);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      this.setState({
        turma,
        materia,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas`}>Turmas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}`}>{this.state.turma.curso.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Turma virtual - {this.state.materia.nome}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Turma virtual - {this.state.materia.nome}</CardTitle>
            </CardHeader>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Aulas</Tab>
                  <Tab>Arquivos</Tab>
                  <Tab>Tarefas</Tab>
                  <Tab>Fórum</Tab>
                </TabList>
                <TabPanel>
                  <br />
                  <Aulas
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <ArquivosTurmaVirtual
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <Tarefas
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <Forum
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(TurmaVirtual);
