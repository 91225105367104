import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AdaptiveTableNoticias from 'views/components/AdaptiveTableNoticias';

import NoticiasService from 'services/NoticiasService';

class NoticiasRaiz extends React.Component {
	constructor(props) {
		super(props);

		this.idPagina = 'noticias-raiz';
		this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
		this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
		this.like = '';

		this.noticiasService = new NoticiasService();

		this.state = {
			showAlert: false,
			ready: false,

			dadosTabela: {
				rows: [],
				total: undefined,
			},
		};
	}

	async componentDidMount() {
		try {
			await this.carregarNoticiasPublicas(this.paginaSelecionada, this.linhasPorPagina, this.like);

			this.setState({ ready: true });
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarNoticiasPublicas(pagina, linhasPorPagina, like) {
		try {
			const data = await this.noticiasService
				.carregarNoticiasPublicas(this.props.match.params.cidade,
					pagina, linhasPorPagina, like);

			data.noticias.forEach((noticia) => {
				Object.assign(noticia, {
					conteudo: this.adicionarToken(noticia.conteudo),
				});
			});

			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela, {
					rows: data.noticias, total: data.total
				}), cidade: data.cidadeEstado
			});
		} catch (e) {
			this.setState({
				erro: true,
			});
		}
	}

	adicionarToken(conteudoHtml) {
		return conteudoHtml.replace(/(src)="([^"]+)"/g, (match, atributo, url) => {
			const novaUrl = url.replace(
				`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}`,
				`${env.BACKEND_URL}/resource/arquivo`
			);

			const token = sessionStorage.getItem('auth-token') || '';

			if (novaUrl.includes(env.BACKEND_URL)) {
				const separador = novaUrl.includes("?") ? "&" : "?";
				return `${atributo}="${novaUrl}${separador}token=${token}"`;
			} else {
				return `${atributo}="${url}"`;
			}
		});
	}

	conteudoPagina() {
		return this.state.ready ? <>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<Row>
								<Col>
									<CardTitle tag="h4">Notícias {this.state.cidade}</CardTitle>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<br />
							<AdaptiveTableNoticias
								disableButtons
								cidade={this.props.match.params.cidade}
								selectedPage={this.paginaSelecionada}
								rowsPerPage={this.linhasPorPagina}
								rowsPerPageCallback={(info) => {
									this.paginaSelecionada = 1;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.linhasPorPagina = info.rowsPerPage;
									sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
									this.carregarNoticiasPublicas(
										this.paginaSelecionada, info.rowsPerPage, this.like,
									);
								}}
								likeCallback={(text) => {
									this.like = text;
									this.paginaSelecionada = 1;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.carregarNoticiasPublicas(
										this.paginaSelecionada, this.linhasPorPagina, text,
									);
								}}
								paginatorCallback={(page) => {
									this.paginaSelecionada = page;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.carregarNoticiasPublicas(
										this.paginaSelecionada, this.linhasPorPagina, this.like,
									);
								}}
								rowCallback={(noticia) => this.props.history.push(`/noticias/${this.props.match.params.cidade}/${noticia.id}`)}
								data={this.state.dadosTabela} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#34B5B8"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<>
				<header id="header" className="fixed-top">
					<div className="container d-flex align-items-center">
						<h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
						<nav className="nav-menu d-none d-lg-block">
							<ul>
								<li><NavLink to="/">&nbsp;</NavLink></li>
							</ul>
						</nav>
					</div>
				</header>
				<main id="main">
					<section className="breadcrumbs">
						<div className="container">
							<div className="d-flex justify-content-between align-items-center">
								<h2>Notícias</h2>
								<ol>
									<li><NavLink to="/">Início</NavLink></li>
									<li>Notícias {this.state.cidade}</li>
								</ol>
							</div>
						</div>
					</section>
					<div className="container">
						<Alert
							style={{ marginTop: '20px' }}
							color={this.state.alertColor}
							isOpen={this.state.showAlert}
							toggle={() => { this.setState({ showAlert: false }); }}>
							{this.state.alertMsg}
						</Alert>
					</div>
					<section className="inner-page">
						<div className="container">
							{!this.state.erro
								? this.conteudoPagina()
								: <Card>
									<div align="center" style={{ margin: 50 }}>
										Erro ao buscar informações da página
									</div>
								</Card>
							}
						</div>
					</section>
				</main>
			</>
		);
	}
}

export default withRouter(NoticiasRaiz);
