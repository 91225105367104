/* eslint-disable max-len */
import React from 'react';

import {
	Button,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import HorariosAulas from 'views/components/HorariosAulas';
import LoaderModal from 'views/components/LoaderModal';

import MateriasService from 'services/MateriasService';
import TurmasService from 'services/TurmasService';

import Papel from 'assets/csv/papeis.json';

class HorariosTurma extends React.Component {
  constructor(props) {
    super(props);

    this.horariosAulas = [
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
    ];

    this.state = {
      turma: props.turma,
      escola: props.escola,
      anoLetivo: props.anoLetivo,
      loading: true,
    };

    this.materiasService = new MateriasService();
    this.turmasService = new TurmasService();

    this.dropCallback = this.dropCallback.bind(this);
  }

  async componentDidMount() {
    const materias = await this.materiasService
      .carregarMateriasPorEstruturaCurricular(this.state.turma.estruturaCurricular.id);

    const horarios = await this.turmasService
      .carregarHorariosAulas(this.props.turma.id);

    horarios.forEach((hr) => {
      this.horariosAulas[hr.horario][hr.dia] = { materia: hr.materia };
    });

    this.setState({
      materias,
      loading: false,
      qtdHorarios: horarios.length,
      frequenciaRestrita: this.state.turma.frequenciaRestrita,
    });
  }

  dropCallback(hr, dia, materia) {
    this.horariosAulas[hr][dia] = materia;
    this.setState({
      qtdHorarios: materia === null
        ? this.state.qtdHorarios - 1 : this.state.qtdHorarios + 1,
    });
  }

  async atualizarHorariosAulas() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Atualizando...',
    });

    this.props.callbackMessage({
      showAlert: false,
    });

    try {
      const horarios = [];
      for (let i = 0; i < this.horariosAulas.length; i += 1) {
        for (let j = 0; j < this.horariosAulas[i].length; j += 1) {
          if (this.horariosAulas[i][j]) {
            horarios.push({
              horario: i,
              dia: j,
              materia: this.horariosAulas[i][j].materia,
              turma: { id: this.state.turma.id },
            });
          }
        }
      }

      const frequenciaRestrita = this.state.qtdHorarios > 0
        && this.state.turma.curso.serie > 7 && this.state.frequenciaRestrita;

      await this.turmasService
        .atualizarHorariosAulas(this.state.turma.id, horarios, frequenciaRestrita);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Horários de aula atualizados com sucesso.',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar horários de aula da turma.',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#34B5B8"
        height="50"
        width="50" />
    </div> : <>
      <Row>
        <Col>
          <HorariosAulas
            role={this.props.role}
            sabadoLetivo={this.state.anoLetivo.sabadoLetivo}
            turno={this.state.turma.turno}
            materias={this.state.materias}
            horariosAulas={this.horariosAulas}
            dropCallback={this.dropCallback}
          />
          {this.props.role !== Papel.PROFESSOR.value
            && this.state.qtdHorarios > 0
            && this.state.turma.curso.serie > 7 && <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.frequenciaRestrita}
                  onChange={() => this.setState({
                    frequenciaRestrita: !this.state.frequenciaRestrita,
                  })} />{' '}
                Permitir frequência apenas em dias com horários de aula do componente curricular
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>}
        </Col>
      </Row>
      <br/>
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`)} >
            Voltar
          </Button>
        </Col>
        {
          this.props.role !== Papel.PROFESSOR.value && <Col className="text-right">
            <Button color="primary"
              disabled={this.state.showLoaderModal}
              onClick={() => {
                this.atualizarHorariosAulas();
              }}>
              Atualizar Horários
            </Button>
          </Col>
        }
      </Row>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default HorariosTurma;
