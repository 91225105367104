import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import Turno from 'assets/csv/turnos.json';

import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';

class TurmasAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.turmasService = new TurmasService();

    this.state = {
      showAlert: false,

    };
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const matriculas = await this.turmasService.carregarMatriculasAluno();

      this.setState({
        matriculas,
      });
    } catch (e) {
      this.setState({
        erro: true,
      });
    }
  }

  turmaSelecionada(turma) {
    this.props.history
      .push(`${this.props.layout}/turmas/${turma.id}`);
  }

  conteudoPagina() {
    return this.state.matriculas ? <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem active>Turmas</BreadcrumbItem>
        </Breadcrumb>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Turmas</CardTitle>
          </CardHeader>
          <CardBody>
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '10%' }}>Ano</th>
                  <th style={{ width: '40%' }}>Escola</th>
                  <th style={{ width: '30%' }}>Ano escolar</th>
                  <th style={{ width: '20%' }}>Turno</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.matriculas.map((matricula, index) => (
                    <tr
                      className="tr-clickable"
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.turmaSelecionada(matricula);
                      }}>
                      <td>{matricula.ano}</td>
                      <td>{matricula.escola}</td>
                      <td>{matricula.curso}</td>
                      <td>{this.turnos[matricula.turno].label}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(TurmasAluno);
