import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import Quill from 'views/components/Quill';

import AulasService from 'services/AulasService';
import UsuarioService from 'services/UsuarioService';

import Turno from 'assets/csv/turnos.json';

class AulaProfessor extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.aulasService = new AulasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      erro: false,
    };
  }

  async componentDidMount() {
    try {
      const professor = await this.usuarioService
        .carregarUsuario(this.props.match.params.idProfessor);

      const aula = await this.aulasService
        .carregarAula(this.props.match.params.idAula);

      this.setState({
        professor,
        aula,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.aula ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/aulasprofessores`}>Aulas Professores</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/aulasprofessores/professor/${this.props.match.params.idProfessor}/listagemaulas`}>{this.state.professor.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.aula.nome}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Aula {this.state.aula.nome}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <label>Código</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.aula.codigo}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Quill
                    readOnly
                    value={this.state.aula.conteudo} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(AulaProfessor);
