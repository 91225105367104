import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	FormGroup,
	Table,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import AnosLetivosService from 'services/AnosLetivosService';
import DiariosService from 'services/DiariosService';
import LoginService from 'services/LoginService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class TurmasVirtuais extends React.Component {
	constructor(props) {
		super(props);

		this.anosLetivosService = new AnosLetivosService();
		this.diariosService = new DiariosService();
		this.loginService = new LoginService();

		this.state = {
			showAlert: false,

			diarios: [],
			diarioSelecionado: '',

			materias: [],
		};
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const data = await this.anosLetivosService
				.carregarAnosLetivos(1, 999999, '');

			const anosLetivos = [];
			let anoLetivoSelecionado = '';

			data.anosLetivos.forEach((anoLetivo) => {
				anosLetivos.push({
					label: anoLetivo.ano,
					value: anoLetivo.id,
				});
			});

			if (anosLetivos.length > 0) {
				anoLetivoSelecionado = anosLetivos[anosLetivos.length - 1];

				this.carregarDiarios(anoLetivoSelecionado.value);
			}

			this.setState({
				anosLetivos,
				anoLetivoSelecionado,
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarDiarios(idAnoLetivo) {
		try {
			const data2 = await this.diariosService
				.carregarDiariosDoProfessorPorAnoLetivo(idAnoLetivo, 1, 99999, '');

			const diarios = [];
			let diarioSelecionado = '';

			data2.diarios.forEach((diario) => {
				diarios.push({
					label: `${diario.nome} - ${diario.nomeTurma} - ${diario.escola}`,
					value: diario.id,
					...diario,
				});
			});

			if (diarios.length > 0) {
				[diarioSelecionado] = diarios;
				await this.carregarMateriasDoProfessor(diarioSelecionado);
			}

			this.setState({
				diarios,
				diarioSelecionado,
			});
		} catch (e) {
			this.setState({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Erro ao acessar diários do professor no ano letivo selecionado',
			});
		}
	}

	async carregarMateriasDoProfessor(diario) {
		this.setState({ carregandoMaterias: true });
		try {
			const materias = await this.diariosService
				.carregarMateriasDoProfessor(diario.value);

			this.setState({
				materias,
				carregandoMaterias: false,
			});
		} catch (e) {
			this.setState({
				carregandoMaterias: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: `Erro ao acessar ${this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
					? 'campos de experiências' : 'componentes curriculares'} do diário selecionado`,
			});
		}
	}

	turmaVirtualSelecionada(idMateria) {
		this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
			+ `/turma/${this.state.diarioSelecionado.idTurma}`
			+ `/diario/${this.state.diarioSelecionado.id}`
			+ `/materia/${idMateria}`);
	}

	conteudoPagina() {
		return this.state.anosLetivos
			? <Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem ><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
						<BreadcrumbItem active>Turmas Virtuais</BreadcrumbItem>
					</Breadcrumb>
					<Card className="ava">
						<CardHeader>
							<CardTitle tag="h4">Turmas Virtuais</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="3">
									<FormGroup className={'has-label'}>
										<label>Anos letivos</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className="react-select primary"
											classNamePrefix="react-select"
											isDisabled={this.state.anosLetivos.length === 0}
											value={this.state.anoLetivoSelecionado}
											onChange={(event) => {
												this.setState({ anoLetivoSelecionado: event });
												this.carregarDiarios(event.value);
											}}
											options={this.state.anosLetivos}
											placeholder="Ano letivo..."
										/>
									</FormGroup>
								</Col>
								<Col md="9">
									<FormGroup className={'has-label'}>
										<label>Diários</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className="react-select primary"
											classNamePrefix="react-select"
											isDisabled={this.state.diarios.length === 0}
											value={this.state.diarioSelecionado}
											onChange={(event) => {
												this.setState({ diarioSelecionado: event });
												this.carregarMateriasDoProfessor(event);
											}}
											options={this.state.diarios}
											placeholder="Diários..."
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									{
										!this.state.carregandoMaterias ?
											<>
												<Table hover>
													<thead className="text-primary">
														<tr>
															<th style={{ width: '80%' }}>
																{this.state.diarioSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
																	? 'Campos de experiências' : 'Componentes curriculares'}
															</th>
														</tr>
													</thead>
													<tbody>
														{
															this.state.materias.map((materia, idx) => <tr className='tr-clickable' key={idx}>
																<td onClick={() => this.turmaVirtualSelecionada(materia.id)}>{materia.nome}</td>
															</tr>)
														}
													</tbody>
												</Table>
											</> : <div align="center" style={{ margin: 50 }}>
												<Loader
													type="Oval"
													color="#34B5B8"
													height="50"
													width="50" />
											</div>
									}
									<div className="turmas_virtuais">

									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row> : <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#34B5B8"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return <div className='content'>
			{!this.state.erro
				? this.conteudoPagina()
				: <Card>
					<div align="center" style={{ margin: 50 }}>
						Erro ao buscar informações da escola
					</div>
				</Card>
			}
		</div>;
	}
}

export default withRouter(TurmasVirtuais);
