import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Form,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	FormGroup,
	Input,
	Button,
	Table,
	UncontrolledTooltip
} from 'reactstrap';

import Select from 'react-select';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import MateriasService from 'services/MateriasService';
import AtelieService from 'services/AtelieService';

import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class ItensAtelie extends React.Component {
	constructor(props) {
		super(props);

		this.ciclosEnsino = [
			CicloEnsino.ENSINO_INFANTIL,
			CicloEnsino.ENSINO_FUNDAMENTAL_1,
			CicloEnsino.ENSINO_FUNDAMENTAL_2,
			CicloEnsino.ENSINO_MEDIO,
			CicloEnsino.ENSINO_TECNICO,
			CicloEnsino.EJA,
			CicloEnsino.OUTRO,
			CicloEnsino.CORRECAO_FLUXO,
		];

		this.situacoes = [
			SituacaoEstruturaCurricular.ATIVA,
			SituacaoEstruturaCurricular.INATIVA,
		];

		this.loginService = new LoginService();
		this.cursosService = new CursosService();
		this.estruturasCurricularesService = new EstruturasCurricularesService();
		this.materiasService = new MateriasService();
		this.atelieService = new AtelieService();

		this.state = {
			showAlert: false,

			buttonDisabled: false,
		};
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {

			const curso = await this.cursosService
				.carregarCurso(this.props.match.params.idCurso);

			const estruturaCurricular = await this.estruturasCurricularesService
				.carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

			const materias = (await this.materiasService
				.carregarMateriasPorEstruturaCurricular(this.props.match.params.idEstruturaCurricular))
				.filter((materia) => materia.atelie);

			const materia = await this.materiasService
				.carregarMateria(this.props.match.params.idMateria);

			await this.carregarItensAtelies();

			const titulo1 = `Itens do ateliê para ${materia.nome}`;
			const titulo2 = `Itens do ateliê propostas para os estudantes de ${materia.nome} no ${curso.nome}`;

			materias.forEach((materia) => Object.assign(materia, {
				value: materia.id,
				label: materia.nome,
			}));

			this.setState({
				curso,
				estruturaCurricular,
				materia,
				titulo1,
				titulo2,
				materias,
				materiaSelecionada: materias.find((m) => m.id === this.props.match.params.idMateria),
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarItensAtelies(idMateria) {
		this.setState({ loadingItensAtelie: true });

		if (!idMateria) {
			idMateria = this.props.match.params.idMateria
		}

		const itensAtelie = await this.atelieService
			.carregarItensAteliesPorMateria(this.props.match.params.idMateria);
		
		itensAtelie.forEach((itemAtelie) => Object.assign(itemAtelie, {
			materia: { id: this.props.match.params.idMateria },
		}))

		this.setState({
			loadingItensAtelie: false,
			itensAtelie,
		});
	}

	removerItemAtelie(itemAtelie) {
		const itensAtelie = this.state.itensAtelie.filter(
			(itemAtelie2) => itemAtelie !== itemAtelie2,
		);

		this.setState({ itensAtelie });
	}

	validarCampos() {
		let ret = true;

		this.state.itensAtelie.forEach((itemAtelie) => {
			if (!itemAtelie.descricao) {
				Object.assign(itemAtelie, { descricaoState: 'has-danger' });
				ret = false;
			}
		});

		if (!ret) this.forceUpdate();

		return ret;
	}

	async salvarItensAtelie() {
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Salvando itens do ateliê...',
		});

		try {
			await this.atelieService
				.salvarItensAtelies(this.props.match.params.idMateria, this.state.itensAtelie);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Itens do ateliê salvos com sucesso',
			});

			await this.carregarItensAtelies();
		} catch (e) {

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao salvar itens do ateliê',
			});
		}
	}

	preencherItensAtelie() {
		const itensAtelie = [
			{
				descricao: 'Aplicabilidade',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Assiduidade',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Autonomia',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Cooperação',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Criatividade',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Participação',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Protagonismo',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
			{
				descricao: 'Resolutividade',
				materia: { id: this.state.materiaSelecionada.value },
				ordem: this.state.itensAtelie.length,
			},
		];

		this.setState({ itensAtelie });
	}

	conteudoPagina() {
		return this.state.curso
			? <Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/${this.props.match.params.idEstruturaCurricular}`}>Estrutura curricular</Link></BreadcrumbItem>
						<BreadcrumbItem active>Itens do ateliê para {this.state.materiaSelecionada.nome}
						</BreadcrumbItem>
					</Breadcrumb>
					<Form onSubmit={this.onSubmit}>
						<Card>
							<CardHeader>
								<CardTitle tag="h4">Itens do ateliê propostos para os estudantes de {this.state.materiaSelecionada.nome} no {this.state.curso.nome}</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="4">
										<FormGroup className={`has-label ${this.state.nomeState}`}>
											<label>Estrucura curricular</label>
											<Input
												disabled
												value={this.state.estruturaCurricular.nome}
												type="text"
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Curso</label>
											<Input
												value={this.state.curso.nome}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Ciclo</label>
											<Input
												value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="2">
										<FormGroup className={'has-label'}>
											<label>Situação</label>
											<Input
												value={this.situacoes[this.state.estruturaCurricular.situacao].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<FormGroup className={'has-label has-danger'}>
											<label>Matérias</label>
											<Select
												noOptionsMessage={() => 'Nenhuma entrada'}
												className={`react-select primary`}
												classNamePrefix="react-select"
												value={this.state.materiaSelecionada}
												onChange={async (event) => {
													this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
														+ `/estruturascurriculares/${this.state.estruturaCurricular.id}`
														+ ` /itensatelie/materia/${event.value}`);

													const titulo1 = `Itens do ateliê para ${event.nome}`;
													const titulo2 = `Itens do ateliê propostas para os estudantes de ${event.nome} no ${this.state.curso.nome}`;

													this.setState({
														materiaSelecionada: event,
														titulo1,
														titulo2,
														showAlert: false,
													});

													await this.componentDidMount();
												}}
												options={this.state.materias}
												placeholder="Matérias..."
											/>
										</FormGroup>
									</Col>
									{
										this.props.dadosUsuario.login === 'ojuara' && <Col md="6" className="text-right align-bottom">
											<Button
												onClick={() => this.preencherItensAtelie()}>
												Preencher com itens de ateliê padrão
											</Button>
										</Col>
									}
								</Row>
								<Row>
									<Col>
										<Button
											color="primary"
											onClick={() => {
												this.state.itensAtelie.push({
													descricao: '',
													materia: { id: this.state.materiaSelecionada.value },
													ordem: this.state.itensAtelie.length,
												});
												this.forceUpdate();
											}}>
											Adicionar item ateliê
										</Button>
									</Col>
								</Row>
								{
									!this.state.loadingItensAtelie ? <Row>
										<Col md="12">
											<Table>
												<thead className="text-primary">
													<tr>
														<th style={{ width: '80%' }}>Descrição</th>
														<th className="text-left" style={{ paddingLeft: 20, width: '15%' }}>Ação</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.itensAtelie.map((itemAtelie, index) => (
															<tr key={index}>
																<td>
																	<FormGroup className={`has-label ${itemAtelie.descricaoState}`}>
																		<TextareaAutosize
																			className={`autoheight-curriculo ${itemAtelie.descricaoState}`}
																			value={itemAtelie.descricao}
																			onChange={(event) => {
																				Object.assign(itemAtelie,
																					{
																						descricao: event.target.value,
																						descricaoState: 'primary',
																					});
																				this.forceUpdate();
																			}} />
																		{itemAtelie.descricaoState === 'has-danger' ? (
																			<label className="error">
																				Informe a descrição do item do ateliê.
																			</label>
																		) : null}
																	</FormGroup>
																</td>
																<td className="text-left" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
																	<Button
																		id={`btnRemoverHabilidade${index}`}
																		className="btn-icon"
																		color="danger"
																		size="sm"
																		onClick={() => this.removerItemAtelie(itemAtelie)}
																		type="button">
																		<i className="fa fa-times" />
																	</Button>
																	<UncontrolledTooltip placement="bottom" target={`btnRemoverHabilidade${index}`} delay={0}>
																		Remover competência municipal
																	</UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																</td>
															</tr>
														))
													}
												</tbody>
											</Table>
										</Col>
									</Row> : <div align="center" style={{ margin: 50 }}>
										<Loader
											type="Oval"
											color="#34B5B8"
											height="50"
											width="50" />
									</div>
								}
							</CardBody>
							<CardFooter>
								<Row>
									<Col md="6" className="text-left">
										<Button
											color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.props.history.goBack()} >
											Voltar
										</Button>
									</Col>
									<Col md="6" className="text-right">
										<Button color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.salvarItensAtelie()} >
											Salvar itens do ateliê
										</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</Form>
				</Col>
			</Row> : <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#34B5B8"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className="content">
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text={this.state.loaderModalText} />
			</div>
		);
	}
}

export default withRouter(ItensAtelie);