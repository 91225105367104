import React from 'react';

import {
	Col,
	Row,
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import AtaFinalNotas from 'views/components/AtaFinalNotas';
import AtaFinalResultado from 'views/components/AtaFinalResultado';

import AbonosService from 'services/AbonosService';
import AvaliacoesService from 'services/AvaliacoesService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import TurmasService from 'services/TurmasService';

class AtaFinalNotaTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      turma: this.props.turma,
      diario: this.props.diario,
      loading: true,
    };

    this.turmasService = new TurmasService();
    this.frequenciaService = new FrequenciaService();
    this.abonosService = new AbonosService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
  }

  async componentDidMount() {
    const descricoesAvaliacoes = await this.turmasService
      .carregarDescricoesAvaliacoes(this.state.turma.id);

    const faltas = await this.frequenciaService
      .carregarFaltasDoDiario(this.state.diario.id);

    const abonos = await this.abonosService
      .carregarAbonos(this.state.diario.id);

    const matriculas = await this.diariosService
      .carregarMatriculas(this.state.diario.id);

    const avaliacoes = await this.avaliacoesService
      .carregarAvaliacoesDoDiario(this.state.diario.id);

    const materias = await this.diariosService
      .carregarMaterias(this.state.diario.id);

    this.setState({
      descricoesAvaliacoes,
      faltas,
      abonos,
      matriculas,
      avaliacoes,
      materias,
      loading: false,
    });
  }

  render() {
    return !this.state.loading
      ? <Row>
        <Col>
          <Tabs>
            <TabList>
              {
                this.state.descricoesAvaliacoes
                  .map((desc, idx) => <Tab key={idx}>{desc.nome}</Tab>)
              }
              <Tab>Rendimento Final</Tab>
            </TabList>
            {
              this.state.descricoesAvaliacoes
                .map((desc, idx) => <TabPanel key={idx}>
                  <AtaFinalNotas
                    idDescricaoAvaliacao={desc.id}
                    materias={this.state.materias}
                    matriculas={this.state.matriculas}
                    avaliacoes={this.state.avaliacoes} />
                </TabPanel>)
            }
            <TabPanel>
              <AtaFinalResultado
                descricoesAvaliacoes={this.state.descricoesAvaliacoes}
                turma={this.state.turma}
                materias={this.state.materias}
                matriculas={this.state.matriculas}
                avaliacoes={this.state.avaliacoes}
                faltas={this.state.faltas}
                abonos={this.state.abonos}
                totalAulas={this.state.diario.aulasALancar}
                curso={this.state.turma.curso} />
            </TabPanel>
          </Tabs>
        </Col>
      </Row> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>;
  }
}

export default AtaFinalNotaTurmaMultiseriado;
