import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledTooltip,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import TipoMatricula from 'assets/csv/tipos-matricula.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoAvaliacaoConceitual from 'assets/csv/situacoes-avaliacao-conceitual';
import Serie from 'assets/csv/series2.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json'

import CursosService from 'services/CursosService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import HistoricosService from 'services/HistoricosService';
import EntesService from 'services/EntesService';

class Escolas extends React.Component {
  constructor(props) {
    super(props);

    this.tiposMatricula = [
      TipoMatricula.REGULAR,
      TipoMatricula.DEPENDENCIA,
    ];

    this.situacoesMatricula = [
      { ...SituacaoMatricula.MATRICULADO, isDisabled: true },
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.FALECIDO,
    ];

    this.situacoesAvaliacaoConceitual = [
      SituacaoAvaliacaoConceitual.DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_INFORMADO,
    ];

    this.cursosService = new CursosService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.historicosService = new HistoricosService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      escolaState: '',
      municipioState: '',
      cursoState: '',

      anoState: '',
      frequenciaState: '',
      tipoMatriculaState: '',
      situacaoState: '',

      cursoSelecionado: '',
      tipoMatriculaSelecionado: this.tiposMatricula[0],
      situacaoSelecionada: this.situacoesMatricula[3],

      escola: '',
      municipio: '',
      ano: '',
      diasLetivos: '',
      cargaHoraria: '',
      frequencia: '',

      cursos: [],
      materiasHistorico: [],

      loaderModalText: '',

      historicoSelecionado: null,
      erro: null,
    };

    this.cadastrarHistorico = this.cadastrarHistorico.bind(this);
    this.atualizarHistorico = this.atualizarHistorico.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const dataCursos = await this.cursosService
        .carregarCursos(1, 99999, '');

      const selectCursos = [];
      dataCursos.cursos.forEach((curso) => {
        selectCursos.push({
          label: curso.nome,
          value: curso.id,
          serie: curso.serie,
          cicloEnsino: curso.cicloEnsino,
        });
      });

      this.setState({
        dadosEnte,
        cursos: selectCursos,
      });

      if (this.props.match.params.idHistorico !== undefined) {
        const historico = await this.historicosService
          .carregarHistorico(this.props.match.params.idHistorico);

        const materiasHistorico = await this.historicosService
          .carregarMateriasHistorico(this.props.match.params.idHistorico);

        materiasHistorico.forEach((materia) => Object.assign(materia, {
          mediaFinal: materia.mediaFinal,
          situacao: this.situacoesMatricula[materia.situacao],
          situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[materia.situacaoAvaliacaoConceitual],
        }));

				this.setState({
					historico,
					materiasHistorico,
				}, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      historicoSelecionado: this.state.historico,
      ano: this.state.historico.turma.anoLetivo.ano,
      frequencia: this.state.historico.frequencia,
      escola: this.state.historico.turma.escola.nome,
      municipio: this.state.historico.turma.municipio,
      diasLetivos: this.state.historico.diasLetivos,
      cargaHoraria: this.state.historico.cargaHoraria,
      cursoSelecionado: {
        label: this.state.historico.turma.curso.nome,
        value: this.state.historico.turma.curso.id,
        serie: this.state.historico.turma.curso.serie,
        cicloEnsino: this.state.historico.turma.curso.cicloEnsino,
      },
      tipoMatriculaSelecionado: this.tiposMatricula[this.state.historico.tipo],
      situacaoSelecionada: this.situacoesMatricula[this.state.historico.situacao],
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.escola === '') {
      this.setState({ escolaState: 'has-danger' });
      ret = false;
    }
    if (this.state.municipio === '') {
      this.setState({ municipioState: 'has-danger' });
      ret = false;
    }
    if (!this.state.cursoSelecionado) {
      this.setState({ cursoState: 'danger' });
      ret = false;
    }
    if (!this.state.tipoMatriculaSelecionado) {
      this.setState({ tipoMatriculaState: 'danger' });
      ret = false;
    }
    if (this.state.ano === '') {
      this.setState({ anoState: 'has-danger' });
      ret = false;
    }
    /*if (this.state.diasLetivos === '') {
      this.setState({ diasLetivosState: 'has-danger' });
      ret = false;
    }
    if (this.state.cargaHoraria === '') {
      this.setState({ cargaHorariaState: 'has-danger' });
      ret = false;
    }
    if (this.state.frequencia === '') {
      this.setState({ frequenciaState: 'has-danger' });
      ret = false;
    }*/
    if (!this.state.situacaoSelecionada) {
      this.setState({ situacaoState: 'danger' });
      ret = false;
    }
    this.state.materiasHistorico.forEach((dados) => {
      if (dados.materia === '') {
        Object.assign(dados, { materiaState: 'has-danger' });
        ret = false;
      }
      if (!this.state.dadosEnte.avaliacaoConceitual || this.state.cursoSelecionado.serie > Serie.ENSINO_FUNDAMENTAL_3.value) {
        if (!dados.mediaFinal) {
          Object.assign(dados, { mediaFinalState: 'has-danger' });
          ret = false;
        }
        if (!dados.situacao) {
          Object.assign(dados, { situacaoState: 'danger' });
          ret = false;
        }
      } else {
        if (!dados.situacaoAvaliacaoConceitual) {
          Object.assign(dados, { situacaoAvaliacaoConceitualState: 'danger' });
          ret = false;
        }
      }
    });
    this.forceUpdate();
    return ret;
  }

  async cadastrarHistorico() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando histórico...',
    });

    const materiasHistorico = this.state.materiasHistorico.map((dados) => (
      {
        materia: dados.materia,
        mediaFinal: this.converterParaNumero(dados.mediaFinal),
        situacao: dados.situacao.value,
        situacaoAvaliacaoConceitual: dados.situacaoAvaliacaoConceitual?.value,
      }
    ));

    const historico = {
      aluno: { id: this.props.match.params.idAluno },
      escola: this.state.escola,
      municipio: this.state.municipio,
      curso: { id: this.state.cursoSelecionado.value },
      tipoMatricula: this.state.tipoMatriculaSelecionado.value,
      ano: this.converterParaNumero(this.state.ano),
      diasLetivos: this.converterParaNumero(this.state.diasLetivos),
      cargaHoraria: this.converterParaNumero(this.state.cargaHoraria),
      frequencia: this.converterParaNumero(this.state.frequencia),
      situacao: this.state.situacaoSelecionada.value,
      materiasHistorico,
    };

    try {
      await this.historicosService.cadastrarHistorico(historico);

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Histórico cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar histórico',
      });
    }
  }

  async atualizarHistorico() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando histórico...',
    });

    const materiasHistorico = this.state.materiasHistorico.map((dados) => (
      {
        id: dados.id,
        materia: dados.materia,
        mediaFinal: this.converterParaNumero(dados.mediaFinal || ''),
        situacao: dados.situacao.value,
        situacaoAvaliacaoConceitual: dados.situacaoAvaliacaoConceitual?.value,
      }
    ));

    const historico = {
      id: this.state.historicoSelecionado.id,
      aluno: { id: this.props.match.params.idAluno },
      escola: this.state.escola,
      municipio: this.state.municipio,
      curso: { id: this.state.cursoSelecionado.value },
      tipoMatricula: this.state.tipoMatriculaSelecionado.value,
      ano: this.state.ano,
      diasLetivos: this.state.diasLetivos,
      cargaHoraria: this.state.cargaHoraria,
      frequencia: this.converterParaNumero(this.state.frequencia),
      situacao: this.state.situacaoSelecionada.value,
      materiasHistorico,
    };

    try {
      await this.historicosService.atualizarHistorico(historico);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Histórico atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar histórico',
      });
    }
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      municipioState: '',
      cursoState: '',
      anoState: '',
      diasLetivosState: '',
      cargaHorariaState: '',
      frequenciaState: '',
      tipoMatriculaState: '',
      situacaoState: '',
      materiasHistorico: [],

      escola: '',
      municipio: '',
      ano: '',
      frequencia: '',
      diasLetivos: '',
      cargaHoraria: '',

      cursoSelecionado: '',
      tipoMatriculaSelecionado: this.tiposMatricula[0],
      situacaoSelecionada: this.situacoesMatricula[3],
      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.historicoSelecionado) {
      this.cadastrarHistorico();
    } else {
      this.atualizarHistorico();
    }
  }

  formatarNumero = (val) => {
    if (val.length === 1) {
      return val;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      if (val === '100') {
        return '10,0';
      }
      return `${val[0]},${val[1]}`;
    }
    return '10,0';
  }

  formatarNumero2 = (val) => {
    if (val.length === 1) {
      return `${val}`;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      return `${val[0]}${val[1]},${val[2]}`;
    }
    if (val.length === 4) {
      if (val === '1000') {
        return '100,0';
      }
      return `${val[0]}${val[1]},${val[2]}`;
    }
    return '100,0';
  }

  converterParaNumero = (valor) => parseFloat(valor.replace(',', '.'));

  converterParaString = (valor) => valor.toFixed(1).toString().replace('.', ',')

  conteudoPagina() {
    return (this.props.match.params.idHistorico !== undefined
      && this.state.historicoSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/alunos`}>Alunos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/alunos/historico/${this.props.match.params.idAluno}`}>Histórico</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.historicoSelecionado ? 'Edição' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do histórico</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.escolaState}`}>
                      <label>Escola*</label>
                      <Input
                        value={this.state.escola}
                        maxLength={200}
                        onChange={(evt) => {
                          this.setState({ escola: evt.target.value, escolaState: '' });
                        }} />
                      {this.state.escolaState === 'has-danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.municipioState}`}>
                      <label>Município/UF*</label>
                      <Input
                        value={this.state.municipio}
                        maxLength={200}
                        onChange={(evt) => {
                          this.setState({ municipio: evt.target.value, municipioState: '' });
                        }} />
                      {this.state.municipioState === 'has-danger' ? (
                        <label className="error">
                          Informe o município/UF.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Curso *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.cursoState}`}
                        classNamePrefix="react-select"
                        value={this.state.cursoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            cursoState: 'primary',
                            cursoSelecionado: event,
                            materiasHistorico: event.cicloEnsino === this.state.cursoSelecionado.cicloEnsino ?
                              this.state.materiasHistorico : [],
                          });
                        }}
                        isDisabled={this.props.match.params.idHistorico}
                        options={this.state.cursos}
                        placeholder="Curso..."
                      />
                      {this.state.cursoState === 'danger' ? (
                        <label className="error">
                          Informe o curso.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo matrícula*</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoMatriculaState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoMatriculaSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoMatriculaState: '', tipoMatriculaSelecionado: event });
                        }}
                        options={this.tiposMatricula}
                        placeholder="Tipo matrícula..."
                      />
                      {this.state.tipoMatriculaState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da matrícula
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.anoState}`}>
                      <label>Ano*</label>
                      <Input
                        value={this.state.ano}
                        decimalSeparator={false}
                        allowNegative={false}
                        maxLength={4}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ ano: val.formattedValue, anoState: '' });
                        }}
                        tag={NumberFormat} />
                      {this.state.anoState === 'has-danger' ? (
                        <label className="error">
                          Informe o ano letivo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.diasLetivosState}`}>
                      <label>Dias letivos</label>
                      <Input
                        value={this.state.diasLetivos}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ diasLetivos: val.formattedValue, diasLetivosState: '' });
                        }}
                        tag={NumberFormat} />
                      {this.state.diasLetivosState === 'has-danger' ? (
                        <label className="error">
                          Informe a quantidade de dias letivos.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cargaHorariaState}`}>
                      <label>Carga Horária</label>
                      <Input
                        value={this.state.cargaHoraria}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ cargaHoraria: val.formattedValue, cargaHorariaState: '' });
                        }}
                        tag={NumberFormat} />
                      {this.state.cargaHorariaState === 'has-danger' ? (
                        <label className="error">
                          Informe a carga horária.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.frequenciaState}`}>
                      <label>Frequência (%)</label>
                      <Input
                        value={this.state.frequencia}
                        style={{ textAlign: 'right' }}
                        decimalSeparator=","
                        decimalScale={1}
                        format={this.formatarNumero2}
                        onValueChange={(val) => {
                          this.setState({
                            frequencia: val.formattedValue,
                            frequenciaState: 'primary',
                          });
                        }}
                        onBlur={() => {
                          if (this.state.frequencia
                            && this.state.frequencia.length === 1) {
                            this.setState({
                              frequencia: `${this.state.frequencia},0`,
                            });
                          }
                        }}
                        tag={NumberFormat} />
                      {this.state.frequenciaState === 'has-danger' ? (
                        <label className="error">
                          Informe a frequência do aluno.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação final*</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoState: '', situacaoSelecionada: event });
                        }}
                        options={this.situacoesMatricula}
                        placeholder="Situação final..."
                      />
                      {this.state.situacaoState === 'danger' ? (
                        <label className="error">
                          Informe a situação final da matrícula.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {
                      this.state.cursoSelecionado && <Button
                        color="primary"
                        disabled={this.state.buttonDisabled}
                        onClick={() => {
                          this.setState({
                            materiasHistorico: this.state.materiasHistorico.concat([{
                              materia: '',
                              mediaFinal: '',
                              situacao: this.situacoesMatricula[3],
                              situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                            }]),
                          });
                        }}>
                        Adicionar {this.state.cursoSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'campo de experiência' : 'componente curricular'}
                      </Button>
                    }
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      this.state.cursoSelecionado && <Button
                        color="primary"
                        disabled={this.state.buttonDisabled}
                        onClick={() => {
                          if (this.state.cursoSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
                            this.setState({
                              materiasHistorico: [
                                {
                                  materia: 'O eu, o outro e o nós',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Corpo, gestos e movimentos',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Traços, sons, cores e formas',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Escuta, fala, pensamento e imaginação',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Espaços, tempos, quantidades, relações e transformações',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                              ],
                            });
                          } else if (this.state.cursoSelecionado.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value) {
                            this.setState({
                              materiasHistorico: [
                                {
                                  materia: 'Arte',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Ciências',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Educação Física',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Ensino Religioso',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Geografia',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'História',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Língua Portuguesa',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Matemática',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                              ],
                            });
                          } else {
                            this.setState({
                              materiasHistorico: [
                                {
                                  materia: 'Arte',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Ciências',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Educação Física',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Ensino Religioso',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Geografia',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'História',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Língua Inglesa',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Língua Portuguesa',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                                {
                                  materia: 'Matemática',
                                  mediaFinal: '',
                                  situacao: this.situacoesMatricula[3],
                                  situacaoAvaliacaoConceitual: this.situacoesAvaliacaoConceitual[3],
                                },
                              ],
                            });
                          }
                        }}>
                        Adicionar {this.state.cursoSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'campos de experiências' : 'componentes curriculares'} padrão
                      </Button>
                    }
                  </Col>
                </Row>
                {
                  this.state.materiasHistorico.length > 0
                  && this.state.materiasHistorico.map((dados, idx) => <Row key={idx}>
                    <Col md="3">
                      <FormGroup className={`has-label ${dados.materiaState}`}>
                        <label>Componente curricular*</label>
                        <Input
                          type="text"
                          value={dados.materia}
                          onChange={(evt) => {
														let materia = evt.target.value;
                            //let materia = evt.target.value.toLowerCase();
                            //materia = materia.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                            Object.assign(dados, { materia, materiaState: '' });
                            this.forceUpdate();
                          }} />
                        {dados.materiaState === 'has-danger' ? (
                          <label className="error">
                            Informe o componente curricular
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {
                      this.state.cursoSelecionado && this.state.cursoSelecionado.serie <= Serie.ENSINO_FUNDAMENTAL_3.value && this.state.dadosEnte.avaliacaoConceitual ? <>
                        <Col md="3">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Avaliação conceitual *</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${dados.situacaoAvaliacaoConceitualState}`}
                              classNamePrefix="react-select"
                              value={dados.situacaoAvaliacaoConceitual}
                              onChange={(event) => {
                                Object.assign(dados, { situacaoAvaliacaoConceitualState: '', situacaoAvaliacaoConceitual: event });
                                this.forceUpdate();
                              }}
                              options={this.situacoesAvaliacaoConceitual}
                              placeholder="Avaliação conceitual..."
                            />
                            {dados.situacaoAvaliacaoConceitualState === 'danger' ? (
                              <label className="error">
                                Informe a avaliação conceitual.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </> : <>
                        <Col md="2">
                          <FormGroup className={`has-label ${dados.mediaFinalState}`}>
                            <label>Média final*</label>
                            <Input
                              value={dados.mediaFinal}
                              style={{ textAlign: 'right' }}
                              decimalSeparator=","
                              decimalScale={1}
                              format={this.formatarNumero}
                              onValueChange={(val) => {
                                Object.assign(
                                  dados, { mediaFinal: val.formattedValue, mediaFinalState: '' },
                                );
                                this.forceUpdate();
                              }}
                              onBlur={() => {
                                if (dados.mediaFinal
                                  && dados.mediaFinal.length === 1) {
                                  Object.assign(
                                    dados, { mediaFinal: `${dados.mediaFinal},0` },
                                  );
                                  this.forceUpdate();
                                }
                              }}
                              tag={NumberFormat} />
                            {dados.mediaFinalState === 'has-danger' ? (
                              <label className="error">
                                Informe a média final
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Situação*</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${dados.situacaoState}`}
                              classNamePrefix="react-select"
                              value={dados.situacao}
                              onChange={(event) => {
                                Object.assign(dados, { situacaoState: '', situacao: event });
                                this.forceUpdate();
                              }}
                              options={this.situacoesMatricula}
                              placeholder="Situação..."
                            />
                            {dados.situacaoState === 'danger' ? (
                              <label className="error">
                                Informe a situação.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </>
                    }
                    <Col>
                      <Button
                        style={{ marginTop: 30 }}
                        id={`btnRemoverItem${idx}`}
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        onClick={() => {
                          this.state.materiasHistorico.splice(idx, 1);
                          this.forceUpdate();
                        }}
                        type="button">
                        <i className="fa fa-times" />
                      </Button>
                      <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${idx}`} delay={0}>
                        Excluir
                      </UncontrolledTooltip>
                    </Col>
                  </Row>)
                }
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/alunos/historico/${this.props.match.params.idAluno}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.historicoSelecionado
                        ? <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                          Cadastrar
                        </Button>
                        : <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(Escolas);
