import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import AvaliacaoConceitualService from 'services/AvaliacaoConceitualService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import SituacaoAvaliacaoConceitual from 'assets/csv/situacoes-avaliacao-conceitual';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class AvaliacaoConceitual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoAvaliacaoConceitual.DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_INFORMADO,
    ];

    this.salvarAvaliacoesConceituais = this.salvarAvaliacoesConceituais.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.avaliacaoConceitualService = new AvaliacaoConceitualService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMateriasDoProfessor(this.props.match.params.idDiario);

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({
          label: materia.nome,
          value: materia.id,
        });
      });

      const materiaSelecionada = {
        label: materias[0].nome,
        value: materias[0].id,
      };

      await this.carregarAvaliacoesConceituais(materiaSelecionada.value);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        selectMaterias,
        materiaSelecionada,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAvaliacoesConceituais(idMateria) {
    try {
      this.setState({ carregandoAvaliacoesConceituais: true });

      const matriculas = await this.diariosService
        .carregarMatriculasNaMateria(this.props.match.params.idDiario, idMateria);

      const avaliacoesConceituaisPromises = matriculas.map((matricula) => new Promise(async (resolve) => {
        const avaliacaoConceitual = await this.avaliacaoConceitualService
          .carregarAvaliacaoConceitual(this.props.match.params.idDiario, idMateria, matricula.id);
        Object.assign(avaliacaoConceitual, { matricula })
        resolve(avaliacaoConceitual);
      }));

      const avaliacoesConceituais = await Promise.all(avaliacoesConceituaisPromises);
      
      this.setState({
        matriculas,
        avaliacoesConceituais,
        carregandoAvaliacoesConceituais: false,
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro carregar avaliações conceituais',
      });
    }
    
  }

  async salvarAvaliacoesConceituais() {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {
      await this.avaliacaoConceitualService.salvarAvaliacoesConceituais(
        this.props.match.params.idDiario,
        this.state.materiaSelecionada.value,
        this.state.avaliacoesConceituais,
      );

      await this.carregarAvaliacoesConceituais(this.state.materiaSelecionada.value);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Avaliações conceituais salvas com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar avaliações conceituais',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  tabelaAvaliacaoConceitual() {
    return this.state.carregandoAvaliacoesConceituais
      ? <div align="center" style={{ padding: 30 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
      : 
        <Table>
        <thead className="text-primary">
          <tr>
            <th style={{ width: '80%' }}>Aluno</th>
            <th className="text-center" style={{ width: '20%' }}>Avaliação conceitual</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.avaliacoesConceituais.map((avaliacaoConceitual, idx) => <tr
              key={uniqid()}>
              <td>{avaliacaoConceitual.matricula.usuario.nome}</td>
              <td className="text-center">
                <UncontrolledDropdown>
                  <DropdownToggle
                    aria-haspopup={true}
                    color={this.situacoes[avaliacaoConceitual.situacao].color}
                    data-toggle="collapse"
                    id="acoesAvaliacaoConceitualDropdownMenuLink"
                    onClick={(evt) => evt.stopPropagation()}
                    nav>
                    <Button color={this.situacoes[avaliacaoConceitual.situacao].color}>
                      {this.situacoes[avaliacaoConceitual.situacao].label} <b className="caret" />
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="acoesAvaliacaoConceitualDropdownMenuLink" right>
                    <DropdownItem
                      onClick={() => {
                        if (this.props.role !== Papel.PROFESSOR.value) return;
                        Object.assign(avaliacaoConceitual, {
                          ...avaliacaoConceitual,
                          situacao: SituacaoAvaliacaoConceitual.NAO_INFORMADO.value
                        });
                        this.forceUpdate();
                      }}>
                      {SituacaoAvaliacaoConceitual.NAO_INFORMADO.label}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        if (this.props.role !== Papel.PROFESSOR.value) return;
                        Object.assign(avaliacaoConceitual, {
                          ...avaliacaoConceitual,
                          situacao: SituacaoAvaliacaoConceitual.DESENVOLVIDO.value
                        });
                        this.forceUpdate();
                      }}>
                      {SituacaoAvaliacaoConceitual.DESENVOLVIDO.label}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        if (this.props.role !== Papel.PROFESSOR.value) return;
                        Object.assign(avaliacaoConceitual, {
                          ...avaliacaoConceitual,
                          situacao: SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO.value
                        });
                        this.forceUpdate();
                      }}>
                      {SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO.label}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        if (this.props.role !== Papel.PROFESSOR.value) return;
                        Object.assign(avaliacaoConceitual, {
                          ...avaliacaoConceitual,
                          situacao: SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO.value
                        });
                        this.forceUpdate();
                      }}>
                      {SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO.label}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>)
          }
        </tbody>
      </Table>
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Avaliação conceitual</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Avaliação conceitual
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                      ? 'Campo de experiências' : 'Componente curricular'}</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.materiaSelecionada}
                      onChange={async (event) => {
                        this.setState({ materiaSelecionada: event })
                        await this.carregarAvaliacoesConceituais(event.value);
                      }}
                      options={this.state.selectMaterias}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.tabelaAvaliacaoConceitual()}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value
                    && <Button
                      color="primary"
                      disabled={this.state.showLoaderModal}
                      onClick={this.salvarAvaliacoesConceituais} >
                      Salvar
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando habilidades trabalhadas..." />
      </div>
    );
  }
}

export default withRouter(AvaliacaoConceitual);
