import { withRouter } from 'hoc/withRouter';
import React from 'react';

import env from 'conf/env.json';

// reactstrap components
import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	Row,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import AdaptiveTableNoticias from 'views/components/AdaptiveTableNoticias';
import Alert from 'views/components/Alert';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import NoticiasService from 'services/NoticiasService';

import Papel from 'assets/csv/papeis.json';

class Noticias extends React.Component {
	constructor(props) {
		super(props);

		this.idPagina = `${this.props.dadosUsuario.idVinculo}-noticias`;
		this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
		this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
		this.like = '';

		this.noticiasService = new NoticiasService();

		this.state = {
			showAlert: false,
			ready: false,

			noticiaSelecionada: null,

			showLoaderModal: false,
			showConfirmModal: false,

			dadosTabela: {
				rows: [],
				total: undefined,
			},
		};
	}

	async componentDidMount() {
		try {
			this.carregarNoticias(this.paginaSelecionada, this.linhasPorPagina, this.like);

			this.setState({ ready: true });
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarNoticias(pagina, linhasPorPagina, like) {
		try {
			const data = await this.noticiasService
				.carregarNoticias(pagina, linhasPorPagina, like);

			data.noticias.forEach((noticia) => {
				Object.assign(noticia, {
					conteudo: this.adicionarToken(noticia.conteudo),
				});
			});

			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela,
					{ rows: data.noticias, total: data.total }),
			});
		} catch (e) {
			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Erro ao buscar notícias cadastradas',
			});
		}
	}

	adicionarToken(conteudoHtml) {
		return conteudoHtml.replace(/(src)="([^"]+)"/g, (match, atributo, url) => {
			const novaUrl = url.replace(
				`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}`,
				`${env.BACKEND_URL}/resource/arquivo`
			);

			const token = sessionStorage.getItem('auth-token') || '';

			if (novaUrl.includes(env.BACKEND_URL)) {
				const separador = novaUrl.includes("?") ? "&" : "?";
				return `${atributo}="${novaUrl}${separador}token=${token}"`;
			} else {
				return `${atributo}="${url}"`;
			}
		});
	}

	editarNoticia(noticia) {
		this.props.history.push({
			pathname: `${this.props.layout}/noticias/edicao/${noticia.id}`,
		});
	}

	confirmarRemocaoNoticia(noticia) {
		this.setState({ noticiaSelecionada: noticia, showConfirmModal: true });
	}

	async removerNoticia() {
		this.setState({ showAlert: false, showLoaderModal: true });

		try {
			await this.noticiasService
				.removerNoticia(this.state.noticiaSelecionada.id);

			if (this.state.dadosTabela.total % this.linhasPorPagina === 1
				&& this.paginaSelecionada > 1) {
				this.paginaSelecionada -= 1;
			}

			this.setState({
				dadosTabela: {
					rows: [],
					total: undefined,
				},
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Notícia removida com sucesso',
			});

			this.carregarNoticias(this.paginaSelecionada, this.linhasPorPagina, this.like);
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao remover notícia',
			});
		}
	}

	conteudoPagina() {
		return this.state.ready ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem active>Notícias</BreadcrumbItem>
					</Breadcrumb>
					<Card>
						<CardHeader>
							<Row>
								<Col className="text-left">
									<CardTitle tag="h4">Notícias</CardTitle>
								</Col>
								<Col className="text-right">
									{
										this.props.role === Papel.GESTOR.value && <Button color="primary"
											onClick={() => {
												this.props.history.push(`${this.props.layout}/noticias/cadastro`);
											}}>
											Cadastrar notícia
										</Button>
									}
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<br />
							<AdaptiveTableNoticias
								disableButtons={this.props.role !== Papel.GESTOR.value}
								disableActions={this.props.role !== Papel.GESTOR.value
									&& this.props.role !== Papel.DIRETOR.value
									&& this.props.role !== Papel.SECRETARIO.value}
								selectedPage={this.paginaSelecionada}
								rowsPerPage={this.linhasPorPagina}
								editButtonCallback={(noticia) => this.editarNoticia(noticia)}
								removeButtonCallback={(noticia) => this.confirmarRemocaoNoticia(noticia)}
								rowsPerPageCallback={(info) => {
									this.paginaSelecionada = 1;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.linhasPorPagina = info.rowsPerPage;
									sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
									this.carregarNoticias(this.paginaSelecionada, info.rowsPerPage, this.like);
								}}
								likeCallback={(text) => {
									this.like = text;
									this.paginaSelecionada = 1;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.carregarNoticias(this.paginaSelecionada, this.linhasPorPagina, text);
								}}
								paginatorCallback={(page) => {
									this.paginaSelecionada = page;
									sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
									this.carregarNoticias(this.paginaSelecionada, this.linhasPorPagina, this.like);
								}}
								rowCallback={(noticia) => this.props.history.push({ pathname: `${this.props.layout}/noticias/${noticia.id}` })}
								data={this.state.dadosTabela} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#34B5B8"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<ConfirmModal
					isOpen={this.state.showConfirmModal}
					callback={(confirm) => {
						this.setState({ showConfirmModal: false });
						if (confirm) {
							this.removerNoticia();
						} else {
							this.setState({ noticiaSelecionada: null });
						}
					}}
					text='Confirme a exclusão da notícia' />
				<LoaderModal
					isOpen={this.state.showLoaderModal}
					text='Excluindo...' />
			</div>
		);
	}
}

export default withRouter(Noticias);
