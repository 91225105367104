import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Table,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import AvaliacoesService from 'services/AvaliacoesService';

import Turno from 'assets/csv/turnos.json';

class Relatorios extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.avaliacoesService = new AvaliacoesService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      const avaliacoes = await this.avaliacoesService
        .carregarAvaliacoes(this.props.match.params.idDiario, this.props.match.params.idMateria);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        avaliacoes,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  avaliarAluno(avaliacao) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/relatorios/${this.state.materia.id}`
      + `/aluno/${avaliacao.matricula.usuario.id}`);
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatórios de {this.state.materia.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Relatórios de {this.state.materia.nome}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '45%' }}>Aluno</th>
                    <th style={{ width: '45%' }}>Mãe</th>
                    <th className="text-center">Relatório</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.avaliacoes.map((avaliacao, idx) => (
                      <tr key={idx}>
                        <td>{avaliacao.matricula.usuario.nome}</td>
                        <td>{avaliacao.matricula.usuario.nomeMae}</td>
                        <td className="text-center">
                          <Button
                            id={`btnAvaliar${idx}`}
                            color="primary"
                            size="sm"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              this.avaliarAluno(avaliacao);
                            }}>
                            Relatório
                          </Button>
                          <UncontrolledTooltip placement="bottom" target={`btnAvaliar${idx}`} delay={0}>
                            Lançar relatório do aluno
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando notas..." />
      </div>
    );
  }
}

export default withRouter(Relatorios);
