import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';

import Papel from 'assets/csv/papeis.json';

class CadastroSecretario extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      carregandoSecretarios: false,
      secretarioSelecionado: null,

      secretarios: [],
    };

    this.adicionarSecretario = this.adicionarSecretario.bind(this);
  }


  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoSecretarios: false, secretarios: [] });
      } else {
        this.carregarSecretarios(text);
      }
    }, 1500);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({
        escola,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.secretarioSelecionado) {
      this.setState({ secretarioState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async adicionarSecretario(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    try {
      const secretario = {
        escola: { id: this.props.match.params.idEscola },
        usuario: { id: this.state.secretarioSelecionado.value },
      };

      await this.escolasService.adicionarSecretario(secretario);

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diretor adicionado com sucesso',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao adicionar diretor',
      });
    }
  }

  limparFormulario() {
    this.setState({
      secretarioSelecionado: null,
      secretarios: [],
    });
  }

  async carregarSecretarios(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.SECRETARIO.value, 1, 99999, like);

      const selectSecretarios = [];
      data.usuarios.forEach((secretario) => {
        selectSecretarios.push({
          value: secretario.id,
          label: secretario.nome ? secretario.nome : secretario.credenciais.email,
        });
      });

      this.setState({
        carregandoSecretarios: false,
        secretarios: selectSecretarios,
      });
    } catch (e) {
      this.setState({
        carregandoSecretarios: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar secretários cadastrados',
      });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={'/gestor/escolas'}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Adicionar secretário</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.adicionarSecretario}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Adicionar secretário</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="align-items-end">
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Secretário *</label>
                      <Select
                        isLoading={this.state.carregandoSecretarios}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.secretarioState}`}
                        classNamePrefix="react-select"
                        value={this.state.secretarioSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoSecretarios: text !== '', secretarioState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ secretarioState: 'primary', secretarioSelecionado: event });
                        }}
                        options={this.state.secretarios}
                        placeholder="Digite o nome de um diretor..."
                      />
                      {this.state.secretarioState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um diretor.
                      </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`/gestor/escolas/${this.props.match.params.idEscola}`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Adicionar
                  </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Adicionando secretário..." />
      </div>
    );
  }
}

export default withRouter(CadastroSecretario);
