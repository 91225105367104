import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import env from 'conf/env.json';

import Quill from 'views/components/Quill';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import AulasService from 'services/AulasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class TurmaVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.aulasService = new AulasService();

    this.state = {
      showAlert: false,
      erro: false,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService
          .carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idEscola
        ? await this.escolasService
          .carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idTurma
        ? await this.turmasService
          .carregarTurma(this.props.match.params.idTurma) : null;

      const diario = this.props.match.params.idDiario
        ? await this.diariosService
          .carregarDiario(this.props.match.params.idDiario) : null;

      const materia = this.props.match.params.idMateria
        ? await this.materiasService.carregarMateria(this.props.match.params.idMateria) : null;

      const aula = await this.aulasService
        .carregarAula(this.props.match.params.idAula);

      Object.assign(aula, {
        conteudo: this.adicionarToken(aula.conteudo),
      });

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        aula,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  adicionarToken(conteudoHtml) {
    return conteudoHtml.replace(/(src|href)="([^"]+)"/g, (match, atributo, url) => {
      const novaUrl = url.replace(`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}`, `${env.BACKEND_URL}/resource/arquivo`);
      if (novaUrl.includes(env.BACKEND_URL)) {
        if (novaUrl.includes("?")) {
          return `${atributo}="${novaUrl}&token=${sessionStorage.getItem('auth-token')}"`;
        } else {
          return `${atributo}="${novaUrl}?token=${sessionStorage.getItem('auth-token')}"`;
        }
      } 
    });
  };

  voltar() {
    if (this.props.match.params.idAnoLetivo) {
      if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
        return `${this.props.layout}`
          + `/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.state.escola.id}`
          + `/turma/${this.state.turma.id}`
          + `/diario/${this.state.diario.id}`;
      }
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}/alfabetizacao`;
    }
    return `${this.props.layout}/aulas`;
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.props.match.params.idAnoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
              </> : !this.props.match.params.idTurma ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/aulas`}>Aulas</Link></BreadcrumbItem>
              </> : <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais`}>Turmas Virtuais</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais/turma/${this.props.match.params.idTurma}/diario/${this.props.match.params.idDiario}/materia/${this.props.match.params.idMateria}`}>{this.state.diario.ano} - {this.state.diario.nome} - {this.state.diario.nomeTurma} - {this.state.diario.escola} - {this.state.materia.nome}</Link></BreadcrumbItem>
              </>
            }
            <BreadcrumbItem active>Aula {this.state.aula.nome}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Aula {this.state.aula.nome}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <label>Código</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.aula.codigo}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Quill 
                    readOnly
                    value={this.state.aula.conteudo}/>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(TurmaVirtual);
