import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';

import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class AulasProfessores extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-professores`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarProfessores = this.carregarProfessores.bind(this);

    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: false,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    try {
      await this.loginService.verificarPapel(this.props.role);

      const dadosEnte = await this.entesService.carregarDadosEnte();
      this.setState({ dadosEnte });

      this.carregarProfessores(this.paginaSelecionada, this.linhasPorPagina, '');
    } catch (e) {
      this.setState({ error: true });
    }
  }

  async carregarProfessores(pagina, linhasPorPagina, like) {
    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.PROFESSOR.value, pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar professores cadastrados',
      });
    }
  }

  conteudoPagina() {
    return this.state.dadosEnte ? <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem active>Aulas Professores</BreadcrumbItem>
        </Breadcrumb>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Aulas Professores</CardTitle>
          </CardHeader>
          <CardBody>
            <AdaptiveTable
              clickRows={true}
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarProfessores(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarProfessores(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarProfessores(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(professor) => {
                this.props.history.push(`${this.props.layout}/aulasprofessores/professor/${professor.id}/listagemaulas`);
              }}
              data={this.state.dadosTabela}/>
          </CardBody>
        </Card>
      </Col>
    </Row> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(AulasProfessores);
