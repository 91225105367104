import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Input,
  FormGroup,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import AwsUtil from 'utils/AwsUtil';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import ConteudosLecionadosService from 'services/ConteudosLecionadosService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Papel from 'assets/csv/papeis.json';

class ConteudoLecionado extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-arquivos-diario-${this.props.match.params.idDiario}-materia-${this.props.match.params.idMateria}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.fileInputRef = React.createRef();

    this.salvarConteudosLecionados = this.salvarConteudosLecionados.bind(this);
    this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);
    this.fileChange = this.fileChange.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      arquivosDiario: [],

      error: null,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.conteudosLecionadosService = new ConteudosLecionadosService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const idMateria = this.props.match.params.idMateria || null;

      const materiasDiario = await this.diariosService
        .carregarMaterias(this.props.match.params.idDiario);

      const conteudosLecionados = await this.conteudosLecionadosService
        .carregarConteudosLecionados(this.props.match.params.idDiario, idMateria);
			
      const materia = idMateria ? await this.materiasService
        .carregarMateria(idMateria) : null;

      await this.carregarArquivosDiarioMateria(this.paginaSelecionada,
        this.linhasPorPagina, this.like);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        materiasDiario,
        conteudosLecionados,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });

      if (materia) {
        const materiasProfessor = await this.diariosService
          .carregarMateriasDoProfessor(this.props.match.params.idDiario);

        const selectMaterias = [];
        materiasProfessor.forEach((materia) => {
          selectMaterias.push({
            label: materia.nome,
            value: materia.id,
          });
        });

        const materiaSelecionada = {
          label: materia.nome,
          value: materia.id,
        };

        this.setState({
          selectMaterias,
          materiaSelecionada,
        });
      }

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarArquivosDiarioMateria(pagina, linhasPorPagina, like) {
    try {
      const data = await this.diariosService
        .carregarArquivosDiarioMateria(this.props.match.params.idDiario,
          this.props.match.params.idMateria,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.arquivos, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  async salvarConteudosLecionados() {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.conteudosLecionadosService.salvarConteudosLecionados(
        this.state.diario.id, this.state.conteudosLecionados,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Conteúdos lecionados salvos com sucesso',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar conteúdos lecionados',
      });
    }
  }

  async salvarEmBackground() {
    await this.conteudosLecionadosService.salvarConteudosLecionados(
      this.state.diario.id, this.state.conteudosLecionados,
    );
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.diariosService.salvarArquivoDiarioMateria(
                this.props.match.params.idDiario, this.props.match.params.idMateria, arquivo,
              );

              await this.carregarArquivosDiarioMateria(this.paginaSelecionada,
                this.linhasPorPagina, this.like);

              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo salvo com sucesso.',
              });
            } catch (msg) {
              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao salvar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  confirmarRemocaoArquivoDiarioMateria(arquivo) {
    this.setState({ arquivoSelecionado: arquivo, showConfirmModal: true });
  }

  async removerArquivoDiarioMateria() {
    this.setState({ showAlert: false, buttonDisabled: true });

    try {
      await this.diariosService
        .removerArquivoDiarioMateria(this.state.arquivoSelecionado.id);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Arquivo removido com sucesso',
      });

      await this.carregarArquivosDiarioMateria(this.paginaSelecionada,
        this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover arquivo',
      });
    }
  }

  dataLimiteExcedida(conteudoLecionado) {
    if (!this.state.dadosEnte.limitesBimestrais) return false;

    let dataLimite = null;
    if (conteudoLecionado.descricaoConteudo.nome === '1º bimestre' && this.state.anoLetivo.dataLimiteBimestre1) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre1, 'DD/MM/YYYY');
    } else if (conteudoLecionado.descricaoConteudo.nome === '2º bimestre' && this.state.anoLetivo.dataLimiteBimestre2) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre2, 'DD/MM/YYYY');
    } else if (conteudoLecionado.descricaoConteudo.nome === '3º bimestre' && this.state.anoLetivo.dataLimiteBimestre3) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre3, 'DD/MM/YYYY');
    } else if (conteudoLecionado.descricaoConteudo.nome === '4º bimestre' && this.state.anoLetivo.dataLimiteBimestre4) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre4, 'DD/MM/YYYY');
    }

    if (!dataLimite) return false;

    dataLimite.set('hours', 23);
    dataLimite.set('minutes', 59);
    dataLimite.set('seconds', 59);

    const today = moment();

    return today.isAfter(dataLimite)
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>
              {this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Conteúdos lecionados {this.state.materia ? `em ${this.state.materia.nome}` : ''}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Conteúdos lecionados {this.state.materia ? `em ${this.state.materia.nome}` : ''}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={this.fileChange}
                  ref={this.fileInputRef} />
              </div>
              {
                this.state.materia && <Row>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Componente curricular</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={'react-select primary'}
                        classNamePrefix="react-select"
                        value={this.state.materiaSelecionada}
                        onChange={async (event) => {
                          this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                            + `/escola/${this.state.escola.id}`
                            + `/turma/${this.state.turma.id}`
                            + `/diario/${this.state.diario.id}`
                            + `/conteudo/${event.value}`);

                          this.setState({ loadingConteudosLecionados: true });
                          await this.componentDidMount();
                          this.setState({ loadingConteudosLecionados: false });

                        }}
                        options={this.state.selectMaterias}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              }
              <Row>
                <Col className="mt-3" md="12">
                  {
                    this.state.loadingConteudosLecionados ? <div align="center" style={{ padding: 30 }}>
                      <Loader
                        type="Oval"
                        color="#34B5B8"
                        height="50"
                        width="50" />
                    </div> : <Tabs>
                      <TabList>
                        {
                          this.state.conteudosLecionados
                            .map((conteudoLecionado, idx) => <Tab key={idx}>
                              {conteudoLecionado.descricaoConteudo.nome} *
                            </Tab>)
                        }
                        <Tab>Conteúdo programático</Tab>
                        <Tab>Arquivos</Tab>
                      </TabList>
                      {
                        this.state.conteudosLecionados
                          .map((conteudoLecionado, idx) => <TabPanel key={idx}>
                            <Row>
                              <Col>
                                <Input
                                  disabled={this.props.role !== Papel.PROFESSOR.value || this.dataLimiteExcedida(conteudoLecionado)}
                                  value={conteudoLecionado.conteudo}
                                  onChange={(evt) => {
                                    const diff = evt.target.value.length
                                      - conteudoLecionado.conteudo.length;
                                    Object.assign(
                                      conteudoLecionado, { conteudo: evt.target.value },
                                    );
                                    if ((evt.target.value.length > 0
                                      && evt.target.value.length % 140 === 0) || diff > 140) {
                                      this.salvarEmBackground();
                                    }
                                    this.forceUpdate();
                                  }}
                                  type="textarea"
                                  style={{ minHeight: '500px' }} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="category form-category">
                                  * Campos obrigatórios
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">
                                <Button
                                  color="primary"
                                  onClick={() => this.props.history
                                    .push(this.voltar())} >
                                  Voltar
                                </Button>
                              </Col>
                              <Col className="text-right">
                                {
                                  this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                                    color="primary"
                                    disabled={this.state.buttonDisabled}
                                    onClick={this.salvarConteudosLecionados} >
                                    Salvar conteúdo
                                  </Button>
                                }
                              </Col>
                            </Row>
                          </TabPanel>)
                      }
                      <TabPanel>
                        {
                          this.state.materia ? <>
                            <Row>
                              <Col>
                                <Input
                                  value={this.state.materia.conteudo}
                                  disabled
                                  type="textarea"
                                  style={{ height: '500px' }} />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">
                                <Button
                                  color="primary"
                                  onClick={() => this.props.history
                                    .push(this.voltar())} >
                                  Voltar
                                </Button>
                              </Col>
                            </Row>
                          </>
                            : <>
                              {
                                this.state.materiasDiario.map((materia, idx) => <Row key={idx}>
                                  <Col md="12">
                                    <FormGroup className={'has-label'}>
                                      <label>{materia.nome}</label>
                                      <Input
                                        disabled
                                        value={materia.conteudo}
                                        type="textarea"
                                        style={{ height: '150px' }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>)
                              }
                              <Row>
                                <Col className="text-left">
                                  <Button
                                    color="primary"
                                    onClick={() => this.props.history
                                      .push(this.voltar())} >
                                    Voltar
                                  </Button>
                                </Col>
                              </Row>
                            </>
                        }
                      </TabPanel>
                      <TabPanel>
                        <Row>
                          <Col>
                            <AdaptiveTable
                              disablePrintAction
                              disableEditAction
                              disableRemoveButton={this.props.match.parrole !== Papel.PROFESSOR.value}
                              clickRows
                              selectedPage={this.paginaSelecionada}
                              rowsPerPage={this.linhasPorPagina}
                              disableActions={this.props.role !== Papel.PROFESSOR.value}
                              rowsPerPageCallback={(info) => {
                                this.paginaSelecionada = 1;
                                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                                this.linhasPorPagina = info.rowsPerPage;
                                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                                this.carregarArquivosDiarioMateria(this.paginaSelecionada,
                                  info.rowsPerPage, this.like);
                              }}
                              likeCallback={(text) => {
                                this.like = text;
                                this.paginaSelecionada = 1;
                                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                                this.carregarArquivosDiarioMateria(this.paginaSelecionada,
                                  this.linhasPorPagina, text);
                              }}
                              paginatorCallback={(page) => {
                                this.paginaSelecionada = page;
                                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                                this.carregarArquivosDiarioMateria(this.paginaSelecionada,
                                  this.linhasPorPagina, this.like);
                              }}
                              removeButtonCallback={(arquivo) => this
                                .confirmarRemocaoArquivoDiarioMateria(arquivo)}
                              rowCallback={(arquivo) => AwsUtil.open(arquivo.url)}
                              data={this.state.dadosTabela}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-left">
                            <Button
                              color="primary"
                              onClick={() => this.props.history
                                .push(this.voltar())} >
                              Voltar
                            </Button>
                          </Col>
                          <Col className="text-right">
                            {
                              this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                                color="primary"
                                disabled={this.state.buttonDisabled}
                                onClick={() => this.fileInputRef.current.click()} >
                                Inserir arquivo
                              </Button>
                            }
                          </Col>
                        </Row>
                      </TabPanel>
                    </Tabs>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando conteúdo lecionado..." />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerArquivoDiarioMateria();
            } else {
              this.setState({ arquivoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do arquivo' />
      </div>
    );
  }
}

export default withRouter(ConteudoLecionado);
