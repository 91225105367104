import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	FormGroup,
	Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import env from 'conf/env.json';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import Quill from 'views/components/Quill';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AulasService from 'services/AulasService';

import Turno from 'assets/csv/turnos.json';

class CadastroAula extends React.Component {
	constructor(props) {
		super(props);

		this.idPagina = `${this.props.dadosUsuario.idVinculo}-aulas`;

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.fileInputRef = React.createRef();
		this.fileInputRef2 = React.createRef();

		this.nomeRef = React.createRef();

		this.loginService = new LoginService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.aulasService = new AulasService();

		this.state = {
			showAlert: false,

			showLoaderModal: false,

			erro: false,
		};

		this.cadastrarAula = this.cadastrarAula.bind(this);
		this.atualizarAula = this.atualizarAula.bind(this);
		this.validarCampos = this.validarCampos.bind(this);
		this.limparFormulario = this.limparFormulario.bind(this);
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {
			const anoLetivo = this.props.match.params.idAnoLetivo
				? await this.anosLetivosService
					.carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

			const escola = this.props.match.params.idAnoLetivo
				? await this.escolasService
					.carregarEscola(this.props.match.params.idEscola) : null;

			const turma = this.props.match.params.idAnoLetivo
				? await this.turmasService
					.carregarTurma(this.props.match.params.idTurma) : null;

			const diario = this.props.match.params.idAnoLetivo
				? await this.diariosService
					.carregarDiario(this.props.match.params.idDiario) : null;

			let idDiretorioAula = null;
			if (!this.props.match.params.idAnoLetivo) {
				const stackDir = JSON.parse(sessionStorage.getItem(`${this.idPagina}-stackDir`)) || { data: [{ id: null }] };
				idDiretorioAula = stackDir.data[stackDir.data.length - 1].id;
			}

			let aula = null;

			if (this.props.match.params.idAula !== undefined) {
				aula = await this.aulasService
					.carregarAula(this.props.match.params.idAula);

				Object.assign(aula, {
					conteudo: this.adicionarToken(aula.conteudo),
				});
			} else {
				aula = {
					nome: '',
					conteudo: '',
				};
			}

			this.setState({
				anoLetivo,
				escola,
				turma,
				diario,
				aula,
				idDiretorioAula,
				ready: true,
			});
		} catch (e) {
			console.log(e)
			this.setState({ erro: true });
		}
	}

	adicionarToken(conteudoHtml) {
		return conteudoHtml.replace(/(src)="([^"]+)"/g, (match, atributo, url) => {
			const novaUrl = url.replace(
				`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}`,
				`${env.BACKEND_URL}/resource/arquivo`
			);

			const token = sessionStorage.getItem('auth-token') || '';

			if (novaUrl.includes(env.BACKEND_URL)) {
				const separador = novaUrl.includes("?") ? "&" : "?";
				return `${atributo}="${novaUrl}${separador}token=${token}"`;
			} else {
				return `${atributo}="${url}"`;
			}
		});
	}

	validarCampos() {
		let ret = true;
		if (this.state.aula.nome === '') {
			this.setState({ nomeState: 'has-danger' });
			ret = false;
		}

		if (this.state.aula.conteudo === '') {
			this.setState({ conteudoState: 'has-danger' });
			ret = false;
		}
		return ret;
	}

	async cadastrarAula() {
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Cadastrando aula...',
		});

		try {
			Object.assign(this.state.aula, {
				conteudo: this.state.aula.conteudo.replace(/(\?token=[^"]+)/g, ''),
			});

			if (this.props.match.params.idAnoLetivo) {
				await this.aulasService.cadastrarAulaEAdicionarNaTurma(this.state.aula,
					this.props.match.params.idTurma,
					this.props.match.params.idMateria);
			} else {
				const aula = {
					...this.state.aula,
					diretorio: this.state.idDiretorioAula
						? { id: this.state.idDiretorioAula } : null,
				};
				await this.aulasService.cadastrarAula(aula);
			}

			this.limparFormulario();

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Aula cadastrada com sucesso',
			});
		} catch (e) {
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao cadastrar aula',
			});
		}
	}

	async atualizarAula() {
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Atualizando escola...',
		});

		try {
			const aula = {
				...this.state.aula,
			};

			Object.assign(aula, {
				conteudo: aula.conteudo.replace(/(\?token=[^"]+)/g, ''),
			});

			await this.aulasService.atualizarAula(aula);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Aula atualizada com sucesso',
			});
		} catch (e) {
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao atualizar aula',
			});
		}
	}

	limparFormulario() {
		this.setState({
			aula: {
				nome: '',
				conteudo: '',
			},
		});
	}

	conteudoPagina() {
		return this.state.ready ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						{
							this.props.match.params.idAnoLetivo ? <>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
							</> : <>
								<BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${this.props.layout}/ava/aulas`}>Aulas</Link></BreadcrumbItem>
								<BreadcrumbItem active>{this.state.aula.id ? this.state.aula.nome : 'Cadastro'}</BreadcrumbItem>
							</>
						}
					</Breadcrumb>
					<Card>
						<CardHeader>
							<CardTitle tag="h4">{this.state.aula.id ? this.state.aula.nome : 'Cadastro de aula'}</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="5">
									<FormGroup className={`has-label ${this.state.nomeState}`}>
										<label>Nome *</label>
										<Input
											type="text"
											maxLength="200"
											value={this.state.aula.nome}
											onChange={(e) => this.setState({
												aula: { ...this.state.aula, nome: e.target.value },
												nomeState: 'has-success',
											})}
										/>
										{this.state.nomeState === 'has-danger' ? (
											<label className="error">
												Informe um nome para a aula.
											</label>
										) : null}
									</FormGroup>
								</Col>
								{
									this.props.match.params.idAula !== undefined && <Col md="2">
										<FormGroup>
											<label>Código</label>
											<Input
												disabled
												type="text"
												value={this.state.aula.codigo}
											/>
										</FormGroup>
									</Col>
								}
							</Row>
							<Row>
								<Col>
									<FormGroup className={`has-label ${this.state.conteudoState}`}>
										<label>Conteúdo *</label>
										<Quill
											state={this.state.conteudoState}
											value={this.state.aula.conteudo}
											onChange={(e) => {
												this.setState({ aula: { ...this.state.aula, conteudo: e }, conteudoState: '' });
											}}
											callbackMessage={(msg) => this.setState(msg)}
											callbackUpload={async (arquivo) => {
												const url = await this.aulasService.uploadArquivo(arquivo);
												return url;
											}}
										/>
										{this.state.conteudoState === 'has-danger' ? (
											<label className="error">
												Informe o conteúdo da notícia
											</label>
										) : null}
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<Row>
								<Col md="6" className="text-left">
									<Button color="primary"
										onClick={() => this.props.history.goBack()}>
										Voltar
									</Button>
								</Col>
								<Col md="6" className="text-right">
									{
										!this.state.aula.id
											? <Button color="primary"
												onClick={() => this.cadastrarAula()}>
												Cadastrar
											</Button>
											: <Button color="primary"
												onClick={() => this.atualizarAula()}>
												Atualizar
											</Button>
									}
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#34B5B8"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text={this.state.loaderModalText} />
			</div>
		);
	}
}

export default withRouter(CadastroAula);
