/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import TextoModal from 'views/components/TextoModal';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import TurmasService from 'services/TurmasService';

import Turno from 'assets/csv/turnos.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class InformacoesTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);
    this.situacoesTurma = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.tiposAvaliacao = [
      TipoAvaliacao.NOTA,
      TipoAvaliacao.RELATORIO,
    ];

    props.turma.turmas.forEach((turma) => {
      let tituloTextoModal = '';
      if (turma.curso) {
        if (turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
          if (turma.curso.serie === 0 || turma.curso.serie === 1) {  // Berçário 0
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Bebês com 0 e 1 ano';
          } else if (turma.curso.serie === 2 || turma.curso.serie === 3) { // Berçário 0
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças bem pequenas com 2 e 3 anos';
          } else { // PRÉ1 E PRÉ2
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças pequenas com 4 e 5 anos';
          }
        } else { // ENSINO FUNDAMENTAL 1
          tituloTextoModal = `Competências gerais propostas para os estudantes do ${turma.curso.nome}`;
        }
      }
      let conteudoTextoModal = '';
      if (turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
        conteudoTextoModal = !turma.estruturaCurricular.competenciasGerais
          ? 'Direitos de aprendizagem não informados' : turma.estruturaCurricular.competenciasGerais;
      } else {
        conteudoTextoModal = !turma.estruturaCurricular.competenciasGerais
          ? 'Competências gerais não informadas' : turma.estruturaCurricular.competenciasGerais;
      }
      Object.assign(turma, {
        tituloTextoModal,
        conteudoTextoModal,
      });
    });

    this.state = {
      loading: true,
      turma: props.turma,
    };

    this.turmasService = new TurmasService();

    this.consolidarTurma = this.consolidarTurma.bind(this);
    this.confirmarConsolidacaoTurma = this.confirmarConsolidacaoTurma.bind(this);
    this.reabrirTurma = this.reabrirTurma.bind(this);
    this.confirmarReaberturaTurma = this.confirmarReaberturaTurma.bind(this);
    this.processarTurma = this.processarTurma.bind(this);
  }

  async componentDidMount() {
    const fn = (turma) => new Promise((resolve) => resolve(
      this.turmasService.carregarTotalMatriculasAtivas(turma.id),
    ));

    const actions = this.state.turma.turmas.map(fn);

    const totalMatriculas = await Promise.all(actions);

    this.state.turma.turmas.forEach((turma, idx) => {
      Object.assign(turma, {
        totalMatriculas: totalMatriculas[idx],
      });
    });

    this.setState({
      loading: false,
    });
  }

  verCompetenciasGerais(tituloTextoModal, conteudoTextoModal) {
    this.setState({
      showTextoModal: true,
      tituloTextoModal,
      conteudoTextoModal,
    });
  }

  confirmarConsolidacaoTurma() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a consolidação da turma',
      confirmModalButtonText: 'Consolidar',
      confirmModalAction: this.consolidarTurma,
    });
  }

  async consolidarTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Consolidando turma...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.consolidarTurmaMultiseriado(this.state.turma.id);

      this.setState({
        turma: { ...this.state.turma, situacao: SituacaoTurma.CONSOLIDADA.value },
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma consolidada com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao consolidar turma',
      });
    }
  }

  confirmarReaberturaTurma() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a reabertura da turma',
      confirmModalButtonText: 'Reabrir',
      confirmModalAction: this.reabrirTurma,
    });
  }

  async reabrirTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Reabrindo turma...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.reabrirTurmaMultiseriado(this.state.turma.id);

      this.setState({
        turma: { ...this.state.turma, situacao: SituacaoTurma.ABERTA.value },
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma reaberta com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao reabrir turma',
      });
    }
  }

  async processarTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Processando resultado da turma...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.processarTurmaMultiseriado(this.state.turma.id);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Resultado da turma processado com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao processar resultado da turma',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#34B5B8"
        height="50"
        width="50" />
    </div> : <>
      <Row className="align-items-end">
        <Col md="2">
          <FormGroup className="has-label">
            <label>Situação</label>
            <Input
              disabled
              type="text"
              value={this.situacoesTurma[this.state.turma.situacao].label}
            />
          </FormGroup>
        </Col>
        {this.state.turma.situacao === SituacaoTurma.ABERTA.value
          && this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
          && this.props.role !== Papel.PROFESSOR.value && <Col>
            <Button color="primary" onClick={() => this.confirmarConsolidacaoTurma()}>
              Consolidar turma
            </Button>
          </Col>
        }
        {this.state.turma.situacao === SituacaoTurma.CONSOLIDADA.value
          && this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
          && this.props.role !== Papel.PROFESSOR.value && <Col>
            <Button color="primary" onClick={() => this.confirmarReaberturaTurma()}>
              Reabrir turma
            </Button>
          </Col>
        }
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="has-label">
            <label>Escola</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.escola.nome}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Nome</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.nome}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Turno</label>
            <Input
              disabled
              type="text"
              value={this.turnos[this.state.turma.turno].label}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Número de vagas</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.vagas || 'Não definido'}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <label>Cursos</label>
        </Col>
        <Col md="2">
          <label>Tipo da avaliação</label>
        </Col>
        <Col md="2">
          <label>Total de matrículas</label>
        </Col>
      </Row>
      {
        this.state.turma.turmas.map((turma, idx) => <Row key={idx} className="align-items-end">
          <Col md="4">
            <FormGroup className="has-label">
              <InputGroup>
                <Input
                  disabled
                  type="text"
                  value={turma.curso.nome}
                />
                <InputGroupAddon
                  id={`btnVerCompetenciasGerais${idx}`}
                  addonType="append"
                  className='clickable'
                  onClick={() => this.verCompetenciasGerais(turma.tituloTextoModal, turma.conteudoTextoModal)}>
                  <InputGroupText>
                    <i className="far fa-file-alt clickable-icon" />
                  </InputGroupText>
                </InputGroupAddon>
                <UncontrolledTooltip placement="bottom" target={`btnVerCompetenciasGerais${idx}`} delay={0}>
                  {turma.tituloTextoModal}
                </UncontrolledTooltip>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup className="has-label">
              <Input
                disabled
                type="text"
                value={this.tiposAvaliacao[turma.tipoAvaliacao].label}
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup className="has-label">
              <Input
                disabled
                type="text"
                value={turma.totalMatriculas}
              />
            </FormGroup>
          </Col>
          {
            this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
            && this.props.role !== Papel.PROFESSOR.value && <Col>
              <Button
                color="primary"
                onClick={() => this.props.history
                  .push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}/escola/${this.state.turma.escola.id}/turma/${turma.id}/matricula/aluno`)} >
                Matricular aluno
              </Button>
            </Col>
          }
        </Row>)
      }

      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}/escola/${this.state.turma.escola.id}`)} >
            Voltar
          </Button>
        </Col>
      </Row>
      <TextoModal
        isOpen={this.state.showTextoModal}
        titulo={this.state.tituloTextoModal}
        conteudo={this.state.conteudoTextoModal}
        closeCallback={() => this.setState({ showTextoModal: false })} />
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.state.confirmModalAction();
          } else {
            this.setState({ matriculaSelecionada: null });
          }
        }}
        text={this.state.confirmModalText}
        confirmText={this.state.confirmModalButtonText} />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default InformacoesTurmaMultiseriado;
