import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import NumberFormat from 'react-number-format';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';
import EscolasService from 'services/EscolasService';

import OrigemEntrada from 'assets/csv/origens-estoque-entrada.json';

class CadastroEstoqueEntrada extends React.Component {
  constructor(props) {
    super(props);

    this.origensEstoqueEntrada = [
      { ...OrigemEntrada.ESTOQUE_CENTRAL, isDisabled: true },
      OrigemEntrada.RECURSOS_PROPRIOS,
    ];

    this.estoquesService = new EstoquesService();
    this.escolasService = new EscolasService();

    this.state = {
      loading: true,

      showAlert: false,
      showLoaderModal: false,

      quantidade: '',
      quantidadeState: '',

      observacoes: '',
      observacoesState: '',
      loaderModalText: '',

      estoqueEntradaSelecionada: null,
      origemEstoqueEntradaSelecionada: '',

      erro: null,
    };

    this.cadastrarEstoqueEntrada = this.cadastrarEstoqueEntrada.bind(this);
    this.atualizarEstoqueEntrada = this.atualizarEstoqueEntrada.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const estoque = await this.estoquesService
        .carregarEstoque(this.props.match.params.idEstoque);

      if (this.props.match.params.idEscola !== undefined) {
        const escola = await this.escolasService.carregarEscola(this.props.match.params.idEscola);
        this.setState({ escola });
      }

      const data = await this.estoquesService
        .carregarEstoqueItens(this.props.match.params.idEstoque, 1, 999999, '');

      data.estoqueItens.forEach((estoqueItem) => Object.assign(estoqueItem, {
        label: estoqueItem.nome,
        value: estoqueItem.id,
      }));

      if (this.props.match.params.idEstoqueEntrada !== undefined) {
        const estoqueEntrada = await this.estoquesService
          .carregarEstoqueEntrada(this.props.match.params.idEstoqueEntrada);

        this.setState({ estoqueEntradaSelecionada: estoqueEntrada }, () => this.contentLoad());
      }

      this.setState({
        estoque,
        estoqueItens: data.estoqueItens,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      estoqueItemSelecionado: { value: this.state.estoqueEntradaSelecionada.estoqueItem.id, label: this.state.estoqueEntradaSelecionada.estoqueItem.nome },
      quantidade: this.state.estoqueEntradaSelecionada.quantidade,
      observacoes: this.state.estoqueEntradaSelecionada.observacoes,
      origemEstoqueEntradaSelecionada: this.origensEstoqueEntrada[this.state.estoqueEntradaSelecionada.origem],
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.estoqueItemSelecionado) {
      this.setState({ estoqueItemState: 'danger' });
      ret = false;
    }
    if (this.state.quantidade === '') {
      this.setState({ quantidadeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.origemEstoqueEntradaSelecionada) {
      this.setState({ origemEstoqueEntradaState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarEstoqueEntrada() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando entrada...',
    });

    const estoqueEntrada = {
      estoqueItem: { id: this.state.estoqueItemSelecionado.value },
      quantidade: this.state.quantidade.toString().replace(',', '.'),
      observacoes: this.state.observacoes,
      origem: this.state.origemEstoqueEntradaSelecionada.value,
      escola: this.props.match.params.idEscola ? { id: this.props.match.params.idEscola } : undefined,
    };

    try {
      await this.estoquesService.cadastrarEstoqueEntrada(estoqueEntrada);

      this.limparFormulario();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Entrada cadastrada com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar entrada',
      });
    }
  }

  async atualizarEstoqueEntrada() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando entrada...',
    });

    const estoqueEntrada = {
      id: this.state.estoqueEntradaSelecionada.id,
      estoqueItem: { id: this.state.estoqueItemSelecionado.value },
      quantidade: this.state.quantidade.toString().replace(',', '.'),
      observacoes: this.state.observacoes,
      origem: this.state.origemEstoqueEntradaSelecionada.value,
      escola: this.props.match.params.idEscola ? { id: this.props.match.params.idEscola } : undefined,
    };

    try {
      await this.estoquesService.atualizarEstoqueEntrada(estoqueEntrada);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Entrada atualizada com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar entrada',
      });
    }
  }

  limparFormulario() {
    this.setState({
      observacoes: '',
      origemEstoqueEntradaSelecionada: '',
      estoqueItemSelecionado: '',
      quantidade: '',

      observacoesState: '',
      origemEstoqueEntradaState: '',
      estoqueItemState: '',
      quantidadeState: '',
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.estoqueEntradaSelecionada) {
      this.cadastrarEstoqueEntrada();
    } else {
      this.atualizarEstoqueEntrada();
    }
  }

  voltar() {
    if (this.props.match.params.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`);
    }
  }

  conteudoPagina() {
    return this.state.loading ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        {
          this.props.match.params.idEscola === undefined ? <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques`}>Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueEntradaSelecionada ? this.state.estoqueEntradaSelecionada.nome : 'Cadastro de Entrada'}</BreadcrumbItem>
          </Breadcrumb> : <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueEntradaSelecionada ? this.state.estoqueEntradaSelecionada.nome : 'Cadastro de Entrada'}</BreadcrumbItem>
          </Breadcrumb>
        }
        <Form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Dados da entrada</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Item do estoque *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.estoqueItemState}`}
                      classNamePrefix="react-select"
                      value={this.state.estoqueItemSelecionado}
                      onChange={(event) => {
                        this.setState({ estoqueItemState: '', estoqueItemSelecionado: event });
                      }}
                      options={this.state.estoqueItens}
                      placeholder="Item do estoque..."
                    />
                    {this.state.estoqueItemState === 'danger' ? (
                      <label className="error">
                        Informe o item do estoque.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label ${this.state.quantidadeState}`}>
                    <label>Quantidade *</label>
                    <Input
                      value={this.state.quantidade}
                      decimalSeparator=","
                      decimalScale={1}
                      onValueChange={(val) => {
                        this.setState({ quantidade: val.formattedValue, quantidadeState: '' });
                      }}
                      tag={NumberFormat} />
                    {this.state.quantidadeState === 'has-danger' ? (
                      <label className="error">
                        Informe a quantidade do item.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Origem da entrada *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.origemEstoqueEntradaState}`}
                      classNamePrefix="react-select"
                      value={this.state.origemEstoqueEntradaSelecionada}
                      onChange={(event) => {
                        this.setState({ origemEstoqueEntradaState: '', origemEstoqueEntradaSelecionada: event });
                      }}
                      options={this.origensEstoqueEntrada}
                      placeholder="Origem da entrada..."
                    />
                    {this.state.origemEstoqueEntradaState === 'danger' ? (
                      <label className="error">
                        Informe a origem da entrada.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label ${this.state.observacoesState}`}>
                    <label>Observações</label>
                    <Input
                      value={this.state.observacoes}
                      name="observacoesEstoque"
                      type="textarea"
                      rows="5"
                      onChange={(e) => this.setState({ observacoes: e.target.value, observacoesState: 'has-success' })}
                    />
                    {this.state.observacoesState === 'has-danger' ? (
                      <label className="error">
                        Informe as observações do item.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <div className="category form-category">
                * Campos obrigatórios
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary" onClick={() => this.voltar()} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  {
                    !this.state.estoqueEntradaSelecionada
                      ? <Button type="submit" color="primary" >
                        Cadastrar
                      </Button>
                      : <Button type="submit" style={{ marginRight: 5 }} color="primary">
                        Atualizar
                      </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroEstoqueEntrada);
