import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import isDateFormat from 'is-date-format';
import validarCpf from 'validar-cpf';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ImageCroppModal from 'views/components/ImageCroppModal';
import ImageSourceModal from 'views/components/ImageSourceModal';
import WebCamModal from 'views/components/WebCamModal';

import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import EscolasService from 'services/EscolasService';
import EntesService from 'services/EntesService';

import SituacaoAluno from 'assets/csv/situacoes-aluno.json';
import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';
import TipoSanguineo from 'assets/csv/tipos-sanguineos.json';
import Papel from 'assets/csv/papeis.json';

import Utils from 'utils/Utils';

class CadastroAluno extends React.Component {
  constructor(props) {
    super(props);

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.tiposSanguineos = [
      TipoSanguineo.A_POSITIVO,
      TipoSanguineo.A_NEGATIVO,
      TipoSanguineo.B_POSITIVO,
      TipoSanguineo.B_NEGATIVO,
      TipoSanguineo.AB_POSITIVO,
      TipoSanguineo.AB_NEGATIVO,
      TipoSanguineo.O_POSITIVO,
      TipoSanguineo.O_NEGATIVO,
    ];

    this.pnes = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesBolsaFamilia = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesUtilizaTransporte = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesTemAlergias = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.situacoesAluno = [
      SituacaoAluno.ATIVO,
      SituacaoAluno.INATIVO,
    ];

    this.fileInputRef = React.createRef();

    this.cadastrarAluno = this.cadastrarAluno.bind(this);
    this.atualizarAluno = this.atualizarAluno.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.contentLoad = this.contentLoad.bind(this);

    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.escolasService = new EscolasService();
    this.entesService = new EntesService();

    const nome = props.location.state && props.location.state.nome ?
      props.location.state.nome : '';

    this.state = {
      showAlert: false,
      showImageCropperModal: false,
      showImageSourceModal: false,
      showWebCamModal: false,

      nis: '',
      inep: '',
      telefone: '',
      nome,
      nomeSocial: '',
      cpf: '',
      cartaoSUS: '',
      dataNascimento: '',
      naturalidade: '',
      nomeMae: '',
      profissaoMae: '',
      rgMae: '',
      nomePai: '',
      profissaoPai: '',
      rgPai: '',
      registro: '',
      tamanhoCalca: '',
      tamanhoCamisa: '',
      tamanhoCalcado: '',
      titulo: '',
      militar: '',
      rg: '',
      endereco: '',
      referencia: '',
      utilizaTransporte: '',
      temAlergias: '',
      alergias: '',

      nomeState: '',
      telefoneState: '',
      dataNascimentoState: '',
      naturalidadeState: '',
      nomeMaeState: '',
      cpfState: '',
      cartaoSUSState: '',
      enderecoState: '',
      referenciaState: '',
      utilizaTransporteState: '',
      alergiasState: '',

      fotoState: '',

      sexoState: '',
      etniaState: '',
      tipoSanguineoState: '',
      pneState: '',
      situacaoState: '',

      sexoSelecionado: '',
      etniaSelecionada: '',
      tipoSanguineoSelecionado: '',
      pneSelecionado: '',
      bolsaFamilia: '',

      escolasSelecionadas: [],
      selectEscolas: [],

      buttonDisabled: false,
      loaderModalText: '',

      alunoSelecionado: null,
      erro: null,

      foto: undefined,
    };
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const data = await this.escolasService
        .carregarEscolas(1, 99999, '');

      const selectEscolas = [];
      data.escolas.forEach((escola) => {
        selectEscolas.push({
          value: escola.id,
          label: escola.nome,
        });
      });

      const fotoDefault = await this.usuarioService.carregarFotoDefault();

      let foto = undefined;

      if (this.props.match.params.idAluno !== undefined) {
        const aluno = await this.usuarioService
          .carregarUsuario(this.props.match.params.idAluno);

        const escolas = await this.usuarioService.carregarEscolasUsuario(aluno.id, Papel.ALUNO.value);
        const escolasSelecionadas = [];
        escolas.forEach((escola) => escolasSelecionadas.push({ value: escola.id, label: escola.nome }));

        foto = await this.usuarioService.carregarFoto(aluno.id);
        
        this.setState({
          alunoSelecionado: aluno,
          escolasSelecionadas,
        }, () => this.contentLoad());
      } 

      this.setState({
        selectEscolas,
        dadosEnte,
        foto : foto ? foto : fotoDefault,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nis: this.state.alunoSelecionado.nis || '',
      inep: this.state.alunoSelecionado.inep || '',
      telefone: this.state.alunoSelecionado.credenciais.telefone || '',
      naturalidade: this.state.alunoSelecionado.naturalidade || '',
      nome: this.state.alunoSelecionado.nome || '',
      nomeSocial: this.state.alunoSelecionado.nomeSocial || '',
      dataNascimento: this.state.alunoSelecionado.dataNascimento || '',
      cpf: this.state.alunoSelecionado.cpf || '',
      cartaoSUS: this.state.alunoSelecionado.cartaoSUS || '',
      nomeMae: this.state.alunoSelecionado.nomeMae,
      profissaoMae: this.state.alunoSelecionado.profissaoMae || '',
      rgMae: this.state.alunoSelecionado.rgMae || '',
      nomePai: this.state.alunoSelecionado.nomePai || '',
      profissaoPai: this.state.alunoSelecionado.profissaoPai || '',
      rgPai: this.state.alunoSelecionado.rgPai || '',
      endereco: this.state.alunoSelecionado.endereco || '',
      referencia: this.state.alunoSelecionado.referencia || '',
      registro: this.state.alunoSelecionado.registro || '',
      tamanhoCalca: this.state.alunoSelecionado.tamanhoCalca || '',
      tamanhoCamisa: this.state.alunoSelecionado.tamanhoCamisa || '',
      tamanhoCalcado: this.state.alunoSelecionado.tamanhoCalcado || '',
      titulo: this.state.alunoSelecionado.titulo || '',
      militar: this.state.alunoSelecionado.militar || '',
      rg: this.state.alunoSelecionado.rg || '',
      sexoSelecionado: this.sexos[this.state.alunoSelecionado.sexo],
      etniaSelecionada: this.etnias[this.state.alunoSelecionado.etnia],
      tipoSanguineoSelecionado: this.tiposSanguineos[this.state.alunoSelecionado.tipoSanguineo],
      pneSelecionado: this.pnes[this.state.alunoSelecionado.pne ? 1 : 0],
      bolsaFamilia: this.opcoesBolsaFamilia[this.state.alunoSelecionado.bolsaFamilia ? 1 : 0],
      utilizaTransporte: this.opcoesUtilizaTransporte[this.state.alunoSelecionado.utilizaTransporte ? 1 : 0],
      temAlergias: this.opcoesTemAlergias[this.state.alunoSelecionado.temAlergias ? 1 : 0],
      alergias: this.state.alunoSelecionado.alergias || '',
    });
  }

  validarCampos() {
    let ret = true;

    const telefone = this.state.telefone.replace(/[()-]/g, '');

    if (this.state.dadosEnte.alunosPorEscola) {
      if (!this.props.match.params.idAluno) {
        if (this.state.escolasSelecionadas.length === 0) {
          this.setState({ escolasState: 'danger' });
          ret = false;
        }
      }
    }
    if (this.state.nis !== '' && this.state.nis.length < 11) {
      this.setState({ nisState: 'has-danger' });
      ret = false;
    }
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (telefone.replace(/[()-]/g, '') !== ''
      && telefone.length < 11) {
      this.setState({ telefoneState: 'has-danger' });
      ret = false;
    }
    if (this.state.cpf !== '' && !validarCpf(this.state.cpf)) {
      this.setState({ cpfState: 'has-danger' });
      ret = false;
    }
    if (this.state.cartaoSUS !== ''
      && !Utils.validarCNS(this.state.cartaoSUS.replace(/\s/g, ''))) {
      this.setState({ cartaoSUSState: 'has-danger' });
      ret = false;
    }
    if (this.state.dataNascimento === '') {
      this.setState({ dataNascimentoState: 'has-danger' });
      ret = false;
    }
    if (!isDateFormat(this.state.dataNascimento, 'dd/mm/yyyy')) {
      this.setState({ dataNascimentoState: 'has-danger' });
      ret = false;
    }
    if (this.state.naturalidade === '') {
      this.setState({ naturalidadeState: 'has-danger' });
      ret = false;
    }
    if (this.state.nomeMae === '') {
      this.setState({ nomeMaeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.sexoSelecionado) {
      this.setState({ sexoState: 'danger' });
      ret = false;
    }
    if (this.state.endereco === '') {
      this.setState({ enderecoState: 'has-danger' });
      ret = false;
    }
    if (!this.state.etniaSelecionada) {
      this.setState({ etniaState: 'danger' });
      ret = false;
    }
    if (!this.state.pneSelecionado) {
      this.setState({ pneState: 'danger' });
      ret = false;
    }
    if (!this.state.bolsaFamilia) {
      this.setState({ bolsaFamiliaState: 'danger' });
      ret = false;
    }
    if (!this.state.utilizaTransporte) {
      this.setState({ utilizaTransporteState: 'danger' });
      ret = false;
    }
    if (!this.state.temAlergias) {
      this.setState({ temAlergiasState: 'danger' });
      ret = false;
    } else if (this.state.temAlergias.value && !this.state.alergias) {
      this.setState({ alergiasState: 'has-danger' });
      ret = false;
    }
    if (!this.state.foto) {
      this.setState({ fotoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async cadastrarAluno() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando aluno...',
    });

    const aluno = {
      nis: this.state.nis,
      inep: this.state.inep,
      telefone: this.state.telefone,
      nome: this.state.nome.trim(),
      nomeSocial: this.state.nomeSocial,
      naturalidade: this.state.naturalidade,
      dataNascimento: this.state.dataNascimento,
      nomeMae: this.state.nomeMae,
      profissaoMae: this.state.profissaoMae,
      rgMae: this.state.rgMae,
      nomePai: this.state.nomePai,
      profissaoPai: this.state.profissaoPai,
      rgPai: this.state.rgPai,
      registro: this.state.registro,
      tamanhoCalca: this.state.tamanhoCalca,
      tamanhoCamisa: this.state.tamanhoCamisa,
      tamanhoCalcado: this.state.tamanhoCalcado,
      titulo: this.state.titulo,
      militar: this.state.militar,
      rg: this.state.rg,
      cpf: this.state.cpf,
      cartaoSUS: this.state.cartaoSUS.replace(/\s/g, ''),
      sexo: this.state.sexoSelecionado.value,
      endereco: this.state.endereco,
      referencia: this.state.referencia,
      etnia: this.state.etniaSelecionada.value,
      tipoSanguineo: this.state.tipoSanguineoSelecionado?.value,
      pne: this.state.pneSelecionado.value,
      bolsaFamilia: this.state.bolsaFamilia.value,
      utilizaTransporte: this.state.utilizaTransporte.value,
      temAlergias: this.state.temAlergias.value,
      alergias: this.state.alergias,
      foto: this.state.foto,
      escolas: this.state.escolasSelecionadas.map((escola) => escola.value).toString(),
    };

    try {
      await this.usuarioService.cadastrarAluno(aluno);
      this.limparFormulario();

      this.props.history.push({ 
        pathname: `${this.props.layout}/alunos/precadastro`,
        state: {
          msg: {
            showAlert: true,
            alertColor: 'success',
            alertMsg: 'Aluno cadastrado com sucesso',
          },
        }
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar aluno',
      });
    }
  }

  async atualizarAluno() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando aluno...',
    });

    const aluno = {
      ...this.state.alunoSelecionado,
      id: this.state.alunoSelecionado.id,
      nis: this.state.nis,
      inep: this.state.inep,
      telefone: this.state.telefone,
      nome: this.state.nome.trim(),
      nomeSocial: this.state.nomeSocial,
      dataNascimento: this.state.dataNascimento,
      naturalidade: this.state.naturalidade,
      nomeMae: this.state.nomeMae,
      profissaoMae: this.state.profissaoMae,
      rgMae: this.state.rgMae,
      nomePai: this.state.nomePai,
      profissaoPai: this.state.profissaoPai,
      rgPai: this.state.rgPai,
      registro: this.state.registro,
      tamanhoCalca: this.state.tamanhoCalca,
      tamanhoCamisa: this.state.tamanhoCamisa,
      tamanhoCalcado: this.state.tamanhoCalcado,
      titulo: this.state.titulo,
      militar: this.state.militar,
      rg: this.state.rg,
      cpf: this.state.cpf,
      cartaoSUS: this.state.cartaoSUS.replace(/\s/g, ''),
      sexo: this.state.sexoSelecionado.value,
      endereco: this.state.endereco,
      referencia: this.state.referencia,
      etnia: this.state.etniaSelecionada.value,
      tipoSanguineo: this.state.tipoSanguineoSelecionado?.value,
      pne: this.state.pneSelecionado.value,
      bolsaFamilia: this.state.bolsaFamilia.value,
      utilizaTransporte: this.state.utilizaTransporte.value,
      temAlergias: this.state.temAlergias.value,
      alergias: this.state.alergias,
      foto: this.state.foto,
      termosDeUso: this.state.alunoSelecionado.termosDeUso,
      credenciais: {
        ...this.state.alunoSelecionado.credenciais,
        telefone: this.state.telefone,
      },
      receberNotificacoesNoticias: this.state.alunoSelecionado.receberNotificacoesNoticias,
      receberNotificacoesTurmas: this.state.alunoSelecionado.receberNotificacoesTurmas,
      escolas: this.state.escolasSelecionadas.map((escola) => escola.value).toString(),
    };

    try {
      await this.usuarioService.atualizarAluno(aluno);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aluno atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar aluno',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nis: '',
      inep: '',
      telefone: '',
      nome: '',
      nomeSocial: '',
      cpf: '',
      cartaoSUS: '',
      dataNascimento: '',
      naturalidade: '',
      nomeMae: '',
      profissaoMae: '',
      rgMae: '',
      nomePai: '',
      profissaoPai: '',
      rgPai: '',
      registro: '',
      tamanhoCalca: '',
      tamanhoCamisa: '',
      tamanhoCalcado: '',
      titulo: '',
      militar: '',
      rg: '',
      endereco: '',
      referencia: '',
      alergias: '',

      nisState: '',
      nomeState: '',
      telefoneState: '',
      dataNascimentoState: '',
      naturalidadeState: '',
      nomeMaeState: '',
      sexoState: '',
      enderecoState: '',
      referenciaState: '',
      alergiasState: '',
      etniaState: '',
      tipoSanguineoState: '',
      pneState: '',
      situacaoState: '',
      cpfState: '',
      cartaoSUSState: '',
      escolasState: '',

      fotoState: '',

      escolasSelecionadas: [],
      sexoSelecionado: '',
      etniaSelecionada: '',
      tipoSanguineoSelecionado: '',
      pneSelecionado: '',
      bolsaFamilia: '',
      utilizaTransporte: '',
      temAlergias: '',
      alunoSelecionado: null,

      buttonDisabled: false,

      foto: this.state.fotoDefault,
    });
  }

  handleImageChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (file.size > 1000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Imagem muito grande. O tamanho máximo para imagem é 1MB',
          });
        } else {
          const novaFoto = reader.result.substring(reader.result.indexOf(',') + 1, reader.result.length);
          this.setState({ novaFoto, showImageCropperModal: true });
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.alunoSelecionado) {
      this.cadastrarAluno();
    } else {
      this.atualizarAluno();
    }
  }

  conteudoPagina() {
    return !this.state.dadosEnte ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/alunos`}>Alunos</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.alunoSelecionado ? 'Edição' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do aluno</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col style={{ marginBottom: 20 }}>
                    <FormGroup className={`has-label  ${this.state.fotoState}`}>
                      <div>
                        <div className="fileinput text-center">
                          <input
                            accept="image/jpeg, image/png"
                            type="file"
                            onChange={this.handleImageChange.bind(this)}
                            ref={this.fileInputRef} />
                        </div>
                        <a href="#foto" onClick={(e) => e.preventDefault()}>
                          <img className="img-err img-profile"
                            alt="..."
                            src={`data:image/jpeg;base64,${this.state.foto}`}
                            width={120}
                            height={160}
                            onClick={() => { this.setState({ showImageSourceModal: true }); }}
                          />
                        </a>
                      </div>
                      {this.state.fotoState !== '' ? (
                        <label className="error">
                          Forneça uma foto 3x4.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state.dadosEnte && this.state.dadosEnte.alunosPorEscola && <>
                    <div className='grupo-info'>
                      <Row>
                        <Col>
                          <FormGroup className="has-label has-danger">
                            <label>Escolas nas quais o aluno estuda *</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.escolasState}`}
                              classNamePrefix="react-select"
                              placeholder="Escolas nas quais o aluno estuda..."
                              closeMenuOnSelect={false}
                              isMulti
                              isDisabled={this.props.match.params.idAluno && this.props.role !== Papel.GESTOR.value}
                              value={this.state.escolasSelecionadas}
                              onChange={(event) => {
                                this.setState({
                                  escolasSelecionadas: event,
                                  escolasState: '',
                                });
                              }}
                              options={this.state.selectEscolas}
                            />
                            {this.state.escolasState === 'danger' ? (
                              <label className="error">
                                Informe ao menos uma escola onde o aluno estuda.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <br />
                  </>
                }
                <div className="grupo-info">
                  <Row>
                    <Col>
                      <h5>Dados do Estudante</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.nomeState}`}>
                        <label>Nome *</label>
                        <Input
                          disabled={this.props.match.params.idAluno !== undefined && this.props.role !== Papel.GESTOR.value}
                          value={this.state.nome}
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({ 
                            nome: evt.target.value, 
                            nomeState: 'has-success',
                          })}
                        />
                        {this.state.nomeState === 'has-danger' ? (
                          <label className="error">
                            Informe o nome do aluno.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.telefoneState}`}>
                        <label>Telefone</label>
                        <Input
                          disabled={this.disableFields}
                          value={this.state.telefone}
                          onChange={(evt) => this.setState({
                            telefone: evt.target.value,
                            telefoneState: '',
                          })}
                          type="text"
                          mask="(99)99999-9999"
                          maskChar=""
                          tag={InputMask}
                        />
                        {this.state.telefoneState === 'has-danger' && (
                          <label className="error">
                            Informe um telefone válido.
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.dataNascimentoState}`}>
                        <label>Data de nascimento *</label>
                        <Input
                          value={this.state.dataNascimento}
                          type="text"
                          mask="99/99/9999"
                          maskChar=""
                          tag={InputMask}
                          onChange={(evt) => this.setState({
                            dataNascimento: evt.target.value,
                            dataNascimentoState: 'has-success',
                          })}
                        />
                        {this.state.dataNascimentoState === 'has-danger' ? (
                          <label className="error">
                            Informe uma data válida.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup className={`has-label ${this.state.naturalidadeState}`}>
                        <label>Naturalidade * </label>
                        <Input
                          value={this.state.naturalidade}
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({
                            naturalidade: evt.target.value,
                            naturalidadeState: 'has-success',
                          })}
                        />
                        {this.state.naturalidadeState === 'has-danger' ? (
                          <label className="error">
                            Informe a naturalidade.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className={`has-label ${this.state.enderecoState}`}>
                        <label>Endereço *</label>
                        <Input
                          value={this.state.endereco}
                          name="enderecoAluno"
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            endereco: evt.target.value,
                            enderecoState: 'has-success',
                          })}
                        />
                        {this.state.enderecoState === 'has-danger' ? (
                          <label className="error">
                            Informe o endereço do aluno.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Sexo *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.sexoState}`}
                          classNamePrefix="react-select"
                          value={this.state.sexoSelecionado}
                          onChange={(event) => {
                            this.setState({ sexoState: 'primary', sexoSelecionado: event });
                          }}
                          options={this.sexos}
                          placeholder="Sexo..."
                        />
                        {this.state.sexoState === 'danger' ? (
                          <label className="error">
                            Informe o sexo.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Etnia *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.etniaState}`}
                          classNamePrefix="react-select"
                          value={this.state.etniaSelecionada}
                          onChange={(event) => {
                            this.setState({ etniaState: 'primary', etniaSelecionada: event });
                          }}
                          options={this.etnias}
                          placeholder="Etnia..."
                        />
                        {this.state.etniaState === 'danger' ? (
                          <label className="error">
                            Informe a etnia.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>NEE *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.pneState}`}
                          classNamePrefix="react-select"
                          value={this.state.pneSelecionado}
                          onChange={(event) => {
                            this.setState({ pneState: 'primary', pneSelecionado: event });
                          }}
                          options={this.pnes}
                          placeholder="Necessidades educ. específicas..."
                        />
                        {this.state.pneState === 'danger' ? (
                          <label className="error">
                            Informe se possui necessidades educacionais específicas.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup className={`has-label ${this.state.inepState}`}>
                        <label>ID Censo</label>
                        <Input
                          value={this.state.inep}
                          type="text"
                          mask="99999999999999999999"
                          maskChar=""
                          tag={InputMask}
                          onChange={(evt) => this.setState({ 
                            inep: evt.target.value, 
                            inepState: 'has-success',
                          })}
                        />
                        {this.state.inepState === 'has-danger' ? (
                          <label className="error">
                            ID Censo inválido.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup className={`has-label ${this.state.nisState}`}>
                        <label>NIS</label>
                        <Input
                          value={this.state.nis}
                          type="text"
                          mask="99999999999"
                          maskChar=""
                          tag={InputMask}
                          onChange={(evt) => this.setState({
                            nis: evt.target.value,
                            nisState: 'has-success',
                          })}
                        />
                        {this.state.nisState === 'has-danger' ? (
                          <label className="error">
                            NIS inválido.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className={`has-label ${this.state.cartaoSUSState}`}>
                        <label>Cartão SUS</label>
                        <Input
                          value={this.state.cartaoSUS}
                          type="text"
                          mask="999 9999 9999 9999"
                          maskChar=""
                          onChange={(evt) => this.setState({
                            cartaoSUS: evt.target.value,
                            cartaoSUSState: 'has-success',
                          })}
                          tag={InputMask}
                        />
                        {this.state.cartaoSUSState === 'has-danger' ? (
                          <label className="error">
                            Informe um número do SUS válido.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Bolsa família *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.bolsaFamiliaState}`}
                          classNamePrefix="react-select"
                          value={this.state.bolsaFamilia}
                          onChange={(event) => {
                            this.setState({ bolsaFamiliaState: 'primary', bolsaFamilia: event });
                          }}
                          options={this.opcoesBolsaFamilia}
                          placeholder="Recebe bolsa família..."
                        />
                        {this.state.bolsaFamiliaState === 'danger' ? (
                          <label className="error">
                            Informe se recebe bolsa família.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.referenciaState}`}>
                        <label>Ponto de referência</label>
                        <Input
                          value={this.state.referencia}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            referencia: evt.target.value,
                            referenciaState: 'has-success',
                          })}
                        />
                        {this.state.referenciaState === 'has-danger' ? (
                          <label className="error">
                            Informe o ponto de referência do endereço.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.nomeSocialState}`}>
                        <label>Nome social</label>
                        <Input
                          value={this.state.nomeSocial}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            nomeSocial: evt.target.value,
                            nomeSocialState: 'has-success',
                          })}
                        />
                        {this.state.nomeSocialState === 'has-danger' ? (
                          <label className="error">
                            Informe o nome social do aluno.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Utiliza transporte *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.utilizaTransporteState}`}
                          classNamePrefix="react-select"
                          value={this.state.utilizaTransporte}
                          onChange={(event) => {
                            this.setState({ utilizaTransporteState: 'primary', utilizaTransporte: event });
                          }}
                          options={this.opcoesUtilizaTransporte}
                          placeholder="Utiliza transporte..."
                        />
                        {this.state.utilizaTransporteState === 'danger' ? (
                          <label className="error">
                            Informe se utiliza transporte.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Tipo sanguíneo </label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.tipoSanguineoState}`}
                          classNamePrefix="react-select"
                          value={this.state.tipoSanguineoSelecionado}
                          onChange={(event) => {
                            this.setState({ tipoSanguineoState: 'primary', tipoSanguineoSelecionado: event });
                          }}
                          options={this.tiposSanguineos}
                          placeholder="Tipo sanguíneo..."
                        />
                        {this.state.tipoSanguineoState === 'danger' ? (
                          <label className="error">
                            Informe o tipo sanguíneo.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Tem alergias *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.temAlergiasState}`}
                          classNamePrefix="react-select"
                          value={this.state.temAlergias}
                          onChange={(event) => {
                            this.setState({ 
                              temAlergiasState: 'primary', 
                              temAlergias: event, 
                              alergias: '',
                              alergiasState: '',
                            });
                          }}
                          options={this.opcoesTemAlergias}
                          placeholder="Tem alergias..."
                        />
                        {this.state.temAlergiasState === 'danger' ? (
                          <label className="error">
                            Informe se tem alergias.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {
                      this.state.temAlergias.value && <Col md="8">
                        <FormGroup className={`has-label ${this.state.alergiasState}`}>
                          <label>Alergias *</label>
                          <Input
                            value={this.state.alergias}
                            type="text"
                            maxLength="200"
                            onChange={(evt) => this.setState({
                              alergias: evt.target.value,
                              alergiasState: 'has-success',
                            })}
                          />
                          {this.state.alergiasState === 'has-danger' ? (
                            <label className="error">
                              Informe as alergias do aluno.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    }
                  </Row>
                </div>
                <br />
                <div className="grupo-info">
                  <Row>
                    <Col>
                      <h5>Filiação</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.nomeMaeState}`}>
                        <label>Nome da mãe *</label>
                        <Input
                          value={this.state.nomeMae}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            nomeMae: evt.target.value,
                            nomeMaeState: 'has-success',
                          })}
                        />
                        {this.state.nomeMaeState === 'has-danger' ? (
                          <label className="error">
                            Informe o nome da mãe.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.profissaoMaeState}`}>
                        <label>Profissão da mãe</label>
                        <Input
                          value={this.state.profissaoMae}
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({
                            profissaoMae: evt.target.value,
                            profissaoMaeState: 'has-success',
                          })}
                        />
                        {this.state.profissaoMaeState === 'has-danger' ? (
                          <label className="error">
                            Informe a profissão da mãe.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.rgMaeState}`}>
                        <label>RG da mãe</label>
                        <Input
                          value={this.state.rgMae}
                          placeholder="Nº: - Data Emissão: - Orgão Emissor: - UF: "
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({
                            rgMae: evt.target.value,
                            rgMaeState: 'has-success',
                          })}
                        />
                        {this.state.rgMaeState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados do RG da mãe.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>Pai</label>
                        <Input
                          value={this.state.nomePai}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            nomePai: evt.target.value,
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.profissaoPaiState}`}>
                        <label>Profissão do pai</label>
                        <Input
                          value={this.state.profissaoPai}
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({ 
                            profissaoPai: evt.target.value,
                            profissaoPaiState: 'has-success',
                          })}
                        />
                        {this.state.profissaoPaiState === 'has-danger' ? (
                          <label className="error">
                            Informe a profissão do pai.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup className={`has-label ${this.state.rgPaiState}`}>
                        <label>RG do pai</label>
                        <Input
                          value={this.state.rgPai}
                          placeholder="Nº: - Data Emissão: - Orgão Emissor: - UF: "
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({
                            rgPai: evt.target.value,
                            rgPaiState: 'has-success',
                          })}
                        />
                        {this.state.rgPaiState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados do RG do pai.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <br />
                <div className="grupo-info">
                  <Row>
                    <Col>
                      <h5>Documentos do Estudante</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className={`has-label ${this.state.registroState}`}>
                        <label>Registro de nascimento/casamento</label>
                        <Input
                          value={this.state.registro}
                          placeholder="Nº: - Livro: - Folhas: - Cidade: - UF:"
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            registro: evt.target.value,
                            registroState: 'has-success',
                          })}
                        />
                        {this.state.registroState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados do registro de nascimento/casamento.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={`has-label ${this.state.tituloState}`}>
                        <label>Título de eleitor</label>
                        <Input
                          value={this.state.titulo}
                          placeholder="Nº: - Zona: - UF:"
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            titulo: evt.target.value,
                            tituloState: 'has-success',
                          })}
                        />
                        {this.state.tituloState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados do título de eleitor.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className={`has-label ${this.state.militarState}`}>
                        <label>Situacao Militar</label>
                        <Input
                          value={this.state.militar}
                          placeholder="Nº: - Série: - Categoria:"
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            militar: evt.target.value,
                            militarState: 'has-success',
                          })}
                        />
                        {this.state.militarState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados da situação militar.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={`has-label ${this.state.rgState}`}>
                        <label>RG</label>
                        <Input
                          value={this.state.rg}
                          placeholder="Nº: - Data Emissão: - Orgão Emissor: - UF: "
                          type="text"
                          maxLength="100"
                          onChange={(evt) => this.setState({
                            rg: evt.target.value,
                            rgState: 'has-success',
                          })}
                        />
                        {this.state.rgState === 'has-danger' ? (
                          <label className="error">
                            Informe os dados do RG do estudante.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup className={`has-label ${this.state.cpfState}`}>
                        <label>CPF</label>
                        <Input
                          value={this.state.cpf}
                          type="text"
                          mask="999.999.999-99"
                          maskChar=""
                          onChange={(evt) => this.setState({
                            cpf: evt.target.value,
                            cpfState: 'has-success',
                          })}
                          tag={InputMask}
                        />
                        {this.state.cpfState === 'has-danger' ? (
                          <label className="error">
                            Informe um CPF válido.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <br />
                <div className="grupo-info">
                  <Row>
                    <Col>
                      <h5>Informações de fardamento</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.tamanhoCalcaState}`}>
                        <label>Tamanho da calça</label>
                        <Input
                          value={this.state.tamanhoCalca}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            tamanhoCalca: evt.target.value,
                            tamanhoCalcaState: 'has-success',
                          })}
                        />
                        {this.state.tamanhoCalcaState === 'has-danger' ? (
                          <label className="error">
                            Informe o tamanho da calça.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.tamanhoCamisaState}`}>
                        <label>Tamanho da camisa</label>
                        <Input
                          value={this.state.tamanhoCamisa}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            tamanhoCamisa: evt.target.value,
                            tamanhoCamisaState: 'has-success',
                          })}
                        />
                        {this.state.tamanhoCamisaState === 'has-danger' ? (
                          <label className="error">
                            Informe o tamanho da camisa.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.tamanhoCalcadoState}`}>
                        <label>Tamanho do calçado</label>
                        <Input
                          value={this.state.tamanhoCalcado}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => this.setState({
                            tamanhoCalcado: evt.target.value,
                            tamanhoCalcadoState: 'has-success',
                          })}
                        />
                        {this.state.tamanhoCalcadoState === 'has-danger' ? (
                          <label className="error">
                            Informe o tamanho do calçado.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push(`${this.props.layout}/alunos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.alunoSelecionado
                        ? <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ImageSourceModal
          callback={(source) => {
            this.setState({ showImageSourceModal: false });
            if (source === 1) {
              this.setState({ showWebCamModal: true });
            } else {
              this.fileInputRef.current.click();
            }
          }}
          isOpen={this.state.showImageSourceModal} />
        <ImageCroppModal
          isOpen={this.state.showImageCropperModal}
          aspect={3 / 4}
          callback={(foto) => {
            this.setState({ showImageCropperModal: false });
            if (foto) {
              this.setState({
                foto,
                fotoState: '',
              });
              this.fileInputRef.current.value = '';
            } else if (foto === null) {
              this.setState({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: 'Ocorreu um erro ao recortar imagem.',
              });
            } else {
              this.fileInputRef.current.value = '';
            }
          }}
          src={`data:image/jpeg;base64,${this.state.novaFoto}`} />
        <WebCamModal
          callback={(foto) => {
            this.setState({ showWebCamModal: false });
            if (foto) {
              this.setState({
                showImageCropperModal: true,
                novaFoto: foto,
              });
            } else if (foto === null) {
              this.setState({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: 'Não foi possível obter a imagem da webcam. Verifique se seu computador possui uma webcam ou se ela está em uso por outro programa.',
              });
            }
          }}
          isOpen={this.state.showWebCamModal} />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroAluno);
