import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from 'reactstrap';

import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SolicitacaoRecuperacaoSenhaService from 'services/SolicitacaoRecuperacaoSenhaService';

import RootFooter from './Footer';

class RecuperacaoSenha extends React.Component {
  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.enviar = this.enviar.bind(this);
    this.redoCaptcha = this.redoCaptcha.bind(this);
    this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    this.solicitacaoRecuperacaoSenhaService = new SolicitacaoRecuperacaoSenhaService();

    this.state = {
      showAlert: false,

      emailState: '',

      buttonDisabled: false,
    };
  }

  validarCampos() {
    let ret = true;
    if (!validator.isEmail(this.emailRef.current.value)) {
      this.setState({ emailState: 'has-danger' });
      ret = false;
    }

    if (!ret) {
      this.recaptchaRef.current.reset();
    }

    return ret;
  }

  async enviar() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.recaptchaRef.current.execute();
  }

  async enviarDados() {
    this.setState({ buttonDisabled: true, showAlert: false });

    const recuperacaoSenha = {
      email: this.emailRef.current.value,
    };

    try {
      await this.solicitacaoRecuperacaoSenhaService
        .solicatarRecuperarSenha(recuperacaoSenha);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Um email de recuperação foi enviado para o endereço informado',
      });

      this.limparFormulario();
    } catch (msg) {
      this.recaptchaRef.current.reset();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao enviar email de recuperação',
      });
    }
  }

  redoCaptcha() {
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  }

  async onVerifyRecaptcha() {
    this.enviarDados();
  }

  onErrorRecaptcha() {
    this.setState({
      buttonDisabled: false,
      showAlert: true,
      alertColor: 'danger',
      alertMsg: 'Erro: sua interação foi identificada como um robô. Por favor, recarregue a página e tente novamente.',
    });
  }

  limparFormulario() {
    this.emailRef.current.value = '';

    this.setState({
      emailState: '',

      buttonDisabled: false,
    });
  }

  render() {
    return <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li><NavLink to="/">&nbsp;</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Recuperar senha</h2>
              <ol>
                <li><NavLink to="/">Início</NavLink></li>
                <li><NavLink to="/login">Login</NavLink></li>
                <li>Recuperar senha</li>
              </ol>
            </div>
          </div>
        </section>
        <div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
        <section className="inner-page">
          <div className="container">
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.enviar();
                  }}
                  className="form">
                  <Card className="card-root">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Recuperar senha</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup className={`has-label ${this.state.emailState}`}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-mobile-alt" style={{ marginRight: '10px' }} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          innerRef={this.emailRef}
                          placeholder="Email..."
                          type="text"
                          maxLength="100"
                          onChange={() => { this.setState({ emailState: '' }); }} />
                        {this.state.emailState === 'has-danger' ? (
                          <label className="error">
                            Informe um endereço de email válido.
                          </label>
                        ) : null}
                      </InputGroup>
                      <Row>
                        <ReCAPTCHA
                          ref={this.recaptchaRef}
                          size="invisible"
                          onExpired={this.redoCaptcha}
                          onChange={this.onVerifyRecaptcha}
                          onError={this.onErrorRecaptcha}
                          sitekey="6LfX4rkUAAAAAPrcwxxlJex3L08dtx2gkCFPQH3o"
                        />
                      </Row>
                      <Row>
                        <Col md="12" className="text-right">
                          <Link to="/login">
                            Fazer login
                        </Link>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={this.state.buttonDisabled}>
                            Enviar
                        </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Realizando login...' />
    </>;
  }
}

export default withRouter(RecuperacaoSenha);
