import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	Row,
	Table,
} from 'reactstrap';

import 'react-tabs/style/react-tabs.scss';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import AbonosService from 'services/AbonosService';
import AnosLetivosService from 'services/AnosLetivosService';
import AvaliacoesService from 'services/AvaliacoesService';
import DiariosService from 'services/DiariosService';
import EscolasService from 'services/EscolasService';
import FrequenciaService from 'services/FrequenciaService';
import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import Turno from 'assets/csv/turnos.json';

class AtaFinalAlfabetizacao extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      buttonDisabled: false,
      showLoaderModal: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
    this.frequenciaService = new FrequenciaService();
    this.abonosService = new AbonosService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const descricoesAvaliacoes = await this.turmasService
        .carregarDescricoesAvaliacoes(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMaterias(this.props.match.params.idDiario);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      const faltas = await this.frequenciaService
        .carregarFaltasDoDiario(this.props.match.params.idDiario);

      const abonos = await this.abonosService
        .carregarAbonos(this.props.match.params.idDiario);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materias,
        matriculas,
        faltas,
        abonos,
        descricoesAvaliacoes,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  totalFaltas = (faltas) => faltas.faltasBimestre1
    + faltas.faltasBimestre2
    + faltas.faltasBimestre3
    + faltas.faltasBimestre4;

  resultadoFinal(totalDeFaltas, faltasAbonadas, matricula) {
    if (matricula.situacao !== SituacaoMatricula.MATRICULADO.value) {
      switch (matricula.situacao) {
        case SituacaoMatricula.TRANSFERIDO.value:
          return 'Transferido';
        case SituacaoMatricula.DESISTENTE.value:
          return 'Evadido';
        case SituacaoMatricula.FALECIDO.value:
          return 'Falecido';
        default:
          break;
      }
    }

    const reprovadoFalta = (1 - (totalDeFaltas - faltasAbonadas)
      / this.state.diario.aulasALancar) < this.state.turma.frequenciaMinima / 100;

    if (reprovadoFalta) {
      return 'Reprovado por falta';
    }
    return 'Aprovado';
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/alfabetizacao`}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Ata Final</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Ata Final</CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '60%' }}>Nome</th>
                    <th className="text-center" style={{ width: '10%' }}>% Frequência</th>
                    <th className="text-center" style={{ width: '10%' }}>Faltas</th>
                    <th className="text-center" style={{ width: '10%' }}>Faltas Abonadas</th>
                    <th className="text-center" style={{ width: '20%' }}>Resultado Final</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.matriculas.map((matricula, idx) => <tr key={idx}>
                      <td>{matricula.usuario.nome}</td>
                      <td className="text-center">
                        {
                          (this.state.matriculas[idx].situacao === SituacaoMatricula.DESISTENTE.value
                            || this.state.matriculas[idx].situacao === SituacaoMatricula.TRANSFERIDO.value
                            || this.state.matriculas[idx].situacao === SituacaoMatricula.FALECIDO.value) ? '-'
                            : this.state.diario.aulasALancar
                              ? ((1 - ((this.totalFaltas(this.state.faltas[idx])
                              - this.state.abonos[idx].quantidade) / this.state.diario.aulasALancar)) * 100).toFixed(1).replace('.', ',')
                              : ''
                        }
                      </td>
                      <td className="text-center">{this.totalFaltas(this.state.faltas[idx])}</td>
                      <td className="text-center">{this.state.abonos[idx].quantidade}</td>
                      <td className="text-center">{this.resultadoFinal(this.totalFaltas(this.state.faltas[idx]),
                        this.state.abonos[idx].quantidade, matricula)}</td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/alfabetizacao`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(AtaFinalAlfabetizacao);
