/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';
import TipoDeclaracaoConclusao from 'assets/csv/tipos-declaracao-conclusao.json';
import TipoAssinatura from 'assets/csv/tipos-assinatura.json';
import series from 'assets/csv/series.json';

class ImprimirDeclaracaoConclusao extends React.Component {
  constructor(props) {
    super(props);

    this.tipos = [
      TipoDeclaracaoConclusao.INFANTIL,
      TipoDeclaracaoConclusao.FUNDAMENTAL1,
      TipoDeclaracaoConclusao.FUNDAMENTAL2,
      TipoDeclaracaoConclusao.MEDIO,
    ];

    this.tiposAssinatura = [
      TipoAssinatura.MANUAL,
      TipoAssinatura.AUTOMATICA,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: null,

      escolaSelecionada: null,

      carregandoAlunos: false,
      alunoSelecionado: null,

      tipoSelecionado: null,
      tipoAssinaturaSelecionado: TipoAssinatura.MANUAL,

      alunos: [],
    };

    this.imprimirDeclaracaoConclusao = this.imprimirDeclaracaoConclusao.bind(this);
  }


  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, alunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      if (dadosEnte.bnccNacional) {
        series.shift();
        series[0].label = 'Creche 1';
        series[1].label = 'Creche 2';
        series[2].label = 'Creche 3';
      }
      
      const data = await this.escolasService
        .carregarEscolas(1, 999999, '');

      const selectEscolas = [];
      data.escolas.forEach((escola) => selectEscolas.push({
        value: escola.id,
        label: escola.nome,
      }));

      this.setState({
        selectEscolas,
        dadosEnte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    if (!this.state.tipoSelecionado) {
      this.setState({ tipoState: 'danger' });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      escolaSelecionada: null,
      alunoState: '',
      alunoSelecionado: null,
      alunos: [],
      carregandoAlunos: false,

      tipoSelecionado: null,
    });
  }

  async carregarAlunos(like) {
    try {
      let data;
      if(this.state.dadosEnte.alunosPorEscola) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosEscola(this.state.escolaSelecionada.value, Papel.ALUNO.value, 1, 99999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 99999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async imprimirDeclaracaoConclusao(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo declaração de conclusão de série para pdf...',
    });

    try {
      const pdf = await this.escolasService
        .imprimirDeclaracaoConclusaoSerie(this.state.escolaSelecionada.value,
          this.state.alunoSelecionado.value,
          this.state.tipoSelecionado.value,
          this.state.tipoAssinaturaSelecionado.value);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Declaração de conclusão de série impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir declaração de conclusão de série',
      });
    }
  }

  conteudoPagina() {
    return this.state.selectEscolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/documentos`}>Documentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Imprimir Declaração de Conclusão de Série</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirDeclaracaoConclusao}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir Declaração de Conclusão de Série</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({ escolaState: '', escolaSelecionada: event });
                        }}
                        options={this.state.selectEscolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Aluno *</label>
                      <Select
                        isLoading={this.state.carregandoAlunos}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.alunoState}`}
                        classNamePrefix="react-select"
                        value={this.state.alunoSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ alunoState: 'primary', alunoSelecionado: event });
                        }}
                        options={this.state.alunos}
                        placeholder="Digite o nome de um aluno cadastro no sistema..."
                        isDisabled={!this.state.escolaSelecionada}
                      />
                      {this.state.alunoState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um aluno cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo da declaração de conclusão *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoState: '', tipoSelecionado: event });
                        }}
                        options={series}
                        placeholder="Tipo da declaração de conclusão..."
                        isDisabled={!this.state.alunoSelecionado}
                      />
                      {this.state.tipoState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da declaração de conclusão.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Assinatura *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoAssinaturaState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoAssinaturaSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoAssinaturaState: '', tipoAssinaturaSelecionado: event });
                        }}
                        options={this.tiposAssinatura}
                        placeholder="Tipo de assinatura..."
                      />
                      {this.state.tipoAssinaturaState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da assinatura do documento.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/documentos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirDeclaracaoConclusao);
