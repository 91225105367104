import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';

import Turno from 'assets/csv/turnos.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class GerenciaDiarios extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      showConfirmModal: false,

      loaderModalText: '',

      erro: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      if (turma.multiSeriado) {
        this.props.history.push(`${this.props.layout}`
          + `/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.props.match.params.idEscola}`
          + `/turma/multiseriado/${turma.turmaMultiseriado.id}`);
        return;
      }

      const diarios = await this.diariosService
        .carregarDiariosDaTurma(this.props.match.params.idTurma);

      const fn = (diario) => new Promise((resolve) => resolve(
        this.diariosService.carregarMaterias(diario.id),
      ));

      const actions = diarios.map(fn);

      const materiasDiario = await Promise.all(actions);

      const materias = await this.materiasService
        .carregarMateriasPorEstruturaCurricular(turma.estruturaCurricular.id);

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({ label: materia.nome, value: materia.id });
      });

      diarios.forEach((diario, idx) => {
        const materiasSelecionadas = materiasDiario[idx].map((materiaDiario) => ({
          value: materiaDiario.id,
          label: materiaDiario.nome,
        }));

        Object.assign(diario, {
          materiasSelecionadas,
        });
      });

      this.setState({
        anoLetivo,
        escola,
        turma,
        diarios,
        materias: selectMaterias,
      });

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  removerDiario(diario) {
    if (this.state.diarios.length === 1) return;
    const diarios = this.state.diarios.filter(
      (diario2) => diario !== diario2,
    );

    this.setState({ diarios });
  }

  validarCampos() {
    let ret = true;

    return ret;
  }

  async reagruparDiarios(senha) {
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      this.state.diarios.forEach((diario) => {
        const materias = diario.materiasSelecionadas.map((materia) => ({
          id: materia.value,
        }));

        Object.assign(diario, {
          materias,
        });
      });

      await this.turmasService.reagruparDiarios(this.props.match.params.idTurma, this.state.diarios, senha);
      
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diários atualizados com sucesso.',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar diários',
      });
    }
  }

  conteudoPagina() {
    return !this.state.escola
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <>
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos Letivos</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
              <BreadcrumbItem active>Gerência de diários</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Gerência de diários</CardTitle>
              </CardHeader>
              <CardBody>
                {
                  this.state.diarios.map((diario, idx) => <Row key={idx}>
                    <Col md="3">
                      <FormGroup className={`has-label ${diario.nomeState}`}>
                        <label>Nome do diário*</label>
                        <Input
                          value={diario.nome}
                          type="text"
                          maxLength="200"
                          onChange={(evt) => {
                            Object.assign(diario, {
                              nomeState: '',
                              nome: evt.target.value,
                            });
                            this.forceUpdate();
                          }}

                        />
                        {diario.nomeState === 'has-danger' ? (
                          <label className="error">
                            Informe o nome do diário.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="7">
                      <FormGroup className={'has-label has-danger'}>
                        <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Campos de experiências' : 'Componentes curriculares'} *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${diario.materiasState}`}
                          classNamePrefix="react-select"
                          placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                            ? 'Campos de experiências...' : 'Componentes curriculares...'}
                          closeMenuOnSelect={false}
                          isMulti
                          value={diario.materiasSelecionadas}
                          onChange={(value) => {
                            Object.assign(diario, {
                              materiasState: '',
                              materiasSelecionadas: value ? value : [],
                            })
                            this.forceUpdate();
                          }}
                          options={this.state.materias}
                        />
                        {this.state.materiasState === 'danger' ? (
                          <label className="error">
                            Informe ao menos um componente curricular.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col style={{ paddingTop: '17px', paddingLeft: 20 }}>
                      <Button
                        id={`btnRemoverItem${idx}`}
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        onClick={() => this.removerDiario(diario)}
                        type="button">
                        <i className="fa fa-times" />
                      </Button>
                      <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${idx}`} delay={0}>
                        Remover
                      </UncontrolledTooltip>
                      {idx === this.state.diarios.length - 1
                        && <>
                          <Button
                            id="adicionarItemBtn"
                            className="btn-icon"
                            color="success"
                            size="sm"
                            onClick={() => {
                              this.state.diarios.push({
                                nome: '',
                                materiasSelecionadas: [],
                              });
                              this.forceUpdate();
                            }}>
                            <i className="fa fa-plus-circle" />
                          </Button>
                          <UncontrolledTooltip placement="bottom" target="adicionarItemBtn" delay={0}>
                            Adicionar diário
                          </UncontrolledTooltip>
                        </>
                      }
                    </Col>
                  </Row>)
                }
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button
                      color="primary"
                      onClick={() => this.setState({ showConfirmModal: true })} >
                      Atualizar Diários
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Atualizando diários..." />
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.reagruparDiarios(senha);
            } 
          }}
          buttonText="Alterar"
          deletionInstruction='Confirme que você quer atualizar os diários de classe digitando a sua senha no campo abaixo'
          deletionText={''}
          deletionWarning='A atualização dos diários de classe pode causar perda de informações. Solicite que os professores dos diários façam backup das informações (relatórios, notas, conteúdos lecionados etc) antes de fazer a atualização.' />
      </div>
    );
  }
}

export default withRouter(GerenciaDiarios);
