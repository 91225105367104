import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import MateriasService from 'services/MateriasService';
import EntesService from 'services/EntesService';

import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class EstruturaCurricular extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.situacoes = [
      SituacaoEstruturaCurricular.ATIVA,
      SituacaoEstruturaCurricular.INATIVA,
    ];

    this.loginService = new LoginService();
    this.cursosService = new CursosService();
    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.materiasService = new MateriasService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,

      buttonDisabled: false,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const curso = await this.cursosService
        .carregarCurso(this.props.match.params.idCurso);

      const dadosEnte = await this.entesService.carregarDadosEnte();

      const estruturaCurricular = await this.estruturasCurricularesService
        .carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

      const materias = await this.materiasService
        .carregarMateriasPorEstruturaCurricular(estruturaCurricular.id);

      this.setState({
        curso,
        dadosEnte,
        estruturaCurricular,
        materias,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  adicionarCompetencias(materia) {
    if (this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
      this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
        + `/estruturascurriculares/${this.state.estruturaCurricular.id}`
        + ` /objetivos/materia/${materia.id}`);
    } else {
      this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
        + `/estruturascurriculares/${this.state.estruturaCurricular.id}`
        + ` /competencias/materia/${materia.id}`);
    }
  }

  adicionarCompetenciasMunicipais(materia) {
    this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
      + `/estruturascurriculares/${this.state.estruturaCurricular.id}`
      + ` /competencias/municipais/materia/${materia.id}`);
  }

  adicionarItensAtelie(materia) {
    this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
      + `/estruturascurriculares/${this.state.estruturaCurricular.id}`
      + ` /itensatelie/materia/${materia.id}`);
  }

  conteudoPagina() {
    return this.state.curso
      ? <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
            <BreadcrumbItem active>Estrutura Curricular</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Estrutura curricular</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        disabled
                        value={this.state.estruturaCurricular.nome}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Curso *</label>
                      <Input
                        value={this.state.curso.nome}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Etapa de ensino *</label>
                      <Input
                        value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label'}>
                      <label>Situação *</label>
                      <Input
                        value={this.situacoes[this.state.estruturaCurricular.situacao].label}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label`}>
                      <label>Faixa etária (idade inicial)</label>
                      <Input
                        disabled
                        value={this.state.estruturaCurricular.idadeInicial}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        tag={NumberFormat} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label`}>
                      <label>Faixa etária (idade final)</label>
                      <Input
                        disabled
                        value={this.state.estruturaCurricular.idadeFinal}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        tag={NumberFormat} />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <h5>
                      {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                        ? 'Campos de experiências' : 'Componentes curriculares'}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th style={{ width: '20%' }}>Nome</th>
                          <th style={{ width: '40%' }}>Conteúdo</th>
                          <th style={{ width: '10%' }}>Conteúdo</th>
                          <th className="text-center" style={{ paddingLeft: 20, width: '10%' }}>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.materias.map((materia, index) => (
                            <tr key={index}>
                              <td>
                                <FormGroup className={`has-label ${materia.nomeState}`}>
                                  <Input
                                    disabled
                                    type="text"
                                    value={materia.nome}
                                    maxLength="200"
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label ${materia.conteudoState}`}>
                                  <TextareaAutosize
                                    disabled
                                    className={`autoheight-curriculo ${materia.conteudoState}`}
                                    value={materia.conteudo} />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label ${materia.exerciciosState}`}>
                                  <Input
                                    disabled
                                    type="text"
                                    value={materia.exercicios}
                                    maxLength="200"
                                  />
                                </FormGroup>
                              </td>
                              <td className="text-center" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
                                <div className='buttons-row'>
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      aria-haspopup={true}
                                      color="default"
                                      data-toggle="collapse"
                                      id="acoesEstruturaCurricularDropdownMenuLink"
                                      onClick={(evt) => evt.stopPropagation()}
                                      nav>
                                      <Button color="defaul">
                                        Opções <b className="caret" />
                                      </Button>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                                      <DropdownItem
                                        onClick={() => this.adicionarCompetencias(materia)} >
                                        {
                                          this.state.curso.cicloEnsino
                                            === CicloEnsino.ENSINO_INFANTIL.value
                                            ? 'Direitos de aprendizagem e objetivos de aprendizagem' : 'Competências específicas e habilidades'
                                        }
                                      </DropdownItem>
                                      {
                                        this.state.dadosEnte.competenciasMunicipais && <DropdownItem
                                          onClick={() => this.adicionarCompetenciasMunicipais(materia)} >
                                          Competências Municipais
                                        </DropdownItem>
                                      }
                                      {
                                        this.state.dadosEnte.avaliacaoAtelie && materia.atelie && <DropdownItem
                                          onClick={() => this.adicionarItensAtelie(materia)} >
                                          Itens ateliê
                                        </DropdownItem>
                                      }
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>


                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(EstruturaCurricular);
